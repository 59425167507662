import { Arrow } from 'react-konva';
import { point, polygon } from '@flatten-js/core';

/**
 * компонент, отвечающий за отрисовку и логику направления стрелочек
 * @param {object} firstNode объект первой ноды, поля можно посмотреть в файле setConvasDiagramItems.js
 * @param {object} secondNode объект второй ноды, поля можно посмотреть в файле setConvasDiagramItems.js
 */
const ConvasArrows = ({ firstNode, secondNode }) => {
    //если второй схемы уже нет, то возвращаем ничего
    if (!secondNode) {
        return null;
    }
    //начало стрелки всегда из центра первой ноды. То есть первая нода используется во второй ноде
    const arrowStart = {
        x: secondNode.x + secondNode.width / 2,
        y: secondNode.y + secondNode.height / 2,
    };

    /**
     * получение полигона схемы по четырем точкам: 1. верхний левый угол, 2. верхний правый угол
     * 3. нижний левый угол, 4. нижний правый угол
     */
    const firstPolygon = polygon([
        point(firstNode.x, firstNode.y),
        point(firstNode.x + firstNode.width, firstNode.y),
        point(firstNode.x + firstNode.width, firstNode.y + firstNode.height),
        point(firstNode.x, firstNode.y + firstNode.height),
    ]);

    /**
     * получение полигона стрелки по двум точкам: 1. центр первой ноды
     * 2. центр второй ноды
     */
    const arrowPolygon = polygon([
        point(firstNode.x + firstNode.width / 2, firstNode.y + firstNode.height / 2),
        point(secondNode.x + secondNode.width / 2, secondNode.y + secondNode.height / 2),
    ]);

    //получение массива пересечений между полигоном первой схемы и полигоном стрелки
    const intersectPoint = firstPolygon.intersect(arrowPolygon);

    return intersectPoint.length !== 0 ? (
        <>
            <Arrow
                tension={0.2}
                points={[arrowStart.x, arrowStart.y, intersectPoint[0]?.x, intersectPoint[0]?.y]}
                stroke='#000'
                fill='#000'
                strokeWidth={2}
                pointerWidth={4}
            />
        </>
    ) : null;
};
export default ConvasArrows;
