import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import { ModalContainerWrapper } from './modalContainer-styles';
/**
 * Обертка для всех модалок
 * @param {FunctionalComponent} children JSX Компонент
 * @param {function} handleClose функция для закрытия модалки
 * @param {boolean} open переменная, которая указывает закрыта модалка или нет
 * @returns
 */
const ModalContainer = ({ children, handleClose, open }) => {
	return (
		<ModalContainerWrapper
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<>{children}</>
			</Fade>
		</ModalContainerWrapper>
	);
};

export default ModalContainer;
