import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Context from '../../../store/context';
import ModalContainer from '../../modals/modalContainer';
import AddExampleModal from '../../modals/monacoModals/addExampleModal';
import CodeEditorSingleExample from './codeEditorSingleExample';
import { ExamplesWrapper, ExamplesTitle, NoExamples, HeaderWrapper, CustomButton } from './examplesForEditor-styles';

/**
 * компонент для отрисовки примеров для выбранной схемы
 * @param {array} examples массив примеров для выбранной схемы
 * @param {string} schemeId id выбранной схемы в виде строки
 * @param {number} schemeGroupId id группы для выбранной схемы
 */
const ExamplesForEditor = ({ examples, schemeId, schemeGroupId }) => {
    const { state } = useContext(Context);
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    /**
     * формируем массив jsx эллементов для дальнейшей отрисовки в компоненте
     */
    const examplesArray = useMemo(() => {
        if (examples?.length > 0) {
            return examples.map((element) => (
                <CodeEditorSingleExample
                    key={element.id}
                    id={element.id}
                    positive={element.positive}
                    valid={element.valid}
                    example={element.example}
                    error={element.validation_error}
                />
            ));
        }
    }, [examples]);

    return (
        <>
            <ModalContainer open={open} handleClose={handleClose}>
                <AddExampleModal handleClose={handleClose} schemeGroupId={schemeGroupId} schemeId={schemeId} />
            </ModalContainer>
            <ExamplesWrapper>
                <HeaderWrapper>
                    <ExamplesTitle>{t('Examples')}:</ExamplesTitle>
                    <CustomButton
                        variant="outlined"
                        disabled={schemeId ? !state.isMonacoCodeEqualToStateCode || state.versionInfo?.stable : true}
                        onClick={handleOpen}
                    >
                        {t('Add example')}
                    </CustomButton>
                </HeaderWrapper>

                {examples?.length > 0 ? examplesArray : <NoExamples>{t('No examples')}</NoExamples>}
            </ExamplesWrapper>
        </>
    );
};

export default ExamplesForEditor;
