import {
    ModalWrapper,
    BuildedSchemeTitle,
    PositiveWrapper,
    PositiveText,
    BuildedSchemeMonacoWrapper,
    CustomButton,
} from './buildedSchemeModal-styles';
import { Switch } from '@material-ui/core';
import MonacoEditor from 'react-monaco-editor';
import { useContext, useState } from 'react';
import Context from '../../../store/context';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { ActionsTypes } from '../../../store/reducer';
import { postRequest } from '../../../api/api';
/**
 * компонент для отрисовки модалки создания прмиера для схемы
 * @param {number} schemeGroupId id группы, к которой принадлежит схема
 * @param {string} schemeId id самой схемы
 * @param {function} handleClose aeyrwbz lkz pdrhsnbz vjlfkrb
 * @returns
 */
const AddExampleModal = ({ schemeGroupId, schemeId, handleClose }) => {
    const { state, dispatch, token } = useContext(Context);
    const [code, setCode] = useState('');
    const [positive, setPositive] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const options = {
        selectOnLineNumbers: true,
        automaticLayout: true,
        lineHeight: 19,
        fontSize: 14,
        fontFamily: 'Monaco, monospace',
    };

    const saveExampleHandler = async () => {
        try {
            const requestBody = {
                schema_id: schemeId,
                example: JSON.parse(code),
                positive: positive,
            };
            const data = await postRequest(
                `${state.urls.BASE_URL}examples/?version_id=${schemeGroupId}`,
                requestBody,
                token
            );

            if (!data.isError) {
                enqueueSnackbar('Example added', {
                    variant: 'success',
                });
                dispatch({ type: ActionsTypes.GET_EXAMPLES_FOR_VERSION, payload: [...state.examplesForVersion, data] });
                dispatch({
                    type: ActionsTypes.UPDATE_EXAMPLES_FOR_SELECTED_SCHEME,
                    payload: [...state.selectedScheme.examples, data],
                });
                handleClose();
                setCode('');
            } else {
                const errorKey = Object.keys(data.error.data)[0];
                if (errorKey) {
                    enqueueSnackbar(data.error.data[errorKey], {
                        variant: 'error',
                    });
                } else {
                    enqueueSnackbar('Server error', {
                        variant: 'error',
                    });
                }
            }
        } catch (error) {
            enqueueSnackbar('Wrong JSON', {
                variant: 'error',
            });
        }
    };

    const exampleSwitchChange = () => {
        setPositive((prevState) => !prevState);
    };

    const editorDidMount = (editor) => {
        editor.getModel().onDidChangeContent(() => {
            const code = editor.getValue();
            setCode(() => code);
        });
    };
    return (
        <ModalWrapper>
            <BuildedSchemeTitle>{t('Add example')}</BuildedSchemeTitle>
            <PositiveWrapper>
                <PositiveText>{positive ? 'Positive' : 'Negative'}</PositiveText>
                <Switch color="primary" onChange={exampleSwitchChange} checked={positive} />
            </PositiveWrapper>

            <BuildedSchemeMonacoWrapper type={'addExample'} boxShadow={2}>
                <MonacoEditor
                    editorDidMount={editorDidMount}
                    language="json"
                    theme="vs"
                    value={code}
                    options={options}
                />
            </BuildedSchemeMonacoWrapper>

            <CustomButton type={'addExample'} onClick={saveExampleHandler} newcolor={'green'} variant="outlined">
                {t('Save')}
            </CustomButton>
        </ModalWrapper>
    );
};

export default AddExampleModal;
