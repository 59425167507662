import getRangesFromEditor from './getRangesFromEditor';

/**
 * функция возвращает массив объектов, где каждый объект содержит
 * само занчение этой строки в переменной "content" и информация
 * о местонахождении этой строке в окне редактора в переменной "ranges"
 * @param {object} editor экземпляр редактора
 */
const getValuesFromEditor = (editor) => {
	const ranges = getRangesFromEditor(editor);
	const reg = /"__schema__": \S*/g;
	return ranges.map((range) => {
		if (editor.getModel().getLineContent(range.startLineNumber).match(reg)) {
			return {
				content: editor.getModel().getLineContent(range.startLineNumber).match(reg)[0],
				ranges: range,
			};
		}
		return {
			content: '',
			ranges: range,
		};
	});
};

export default getValuesFromEditor;
