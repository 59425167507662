/**
 * функция генерации объекта с полями для конкретной ноды
 * @param {array} schemes массив всесх схем для выбранной версии
 * @param {number} windowHeigth высота окна пользователя
 * @param {number} headerHeight высота хеддера
 * @param {object} nodeParams параметры каждой ноды : {width: number, heigth: number}
 * @param {array} examplesForVersion массив всех примеров для выбранной версии
 * @param {array} groupsForSource массив всех групп для выбранного источника
 */
const setConvasDiagramItems = (
    schemes,
    windowHeigth,
    headerHeight,
    nodeParams,
    examplesForVersion,
    groupsForSource,
) => {
    const arraOfConvasDiagramItems = [];
    let xCoordinate = 250;
    let yCoordinate = 50;
    const maxHeigth = windowHeigth - headerHeight;
    schemes.forEach((element) => {
        //получение строки из JSON нашей схемы
        const schemeString = JSON.stringify(element.schema, null, 2);

        //получение примеров для текущей схемы из массива всех примеров для текущей версии
        const examples = examplesForVersion.filter((item) => item.schema_id === element.id);

        //получение группы для текущей схемы
        const group = groupsForSource.filter((item) => item.id === element.group_id);

        //регулярка для поиск "__schema__": id
        const regExp = /"__schema__": \S*/gm;

        //получение массива всех совпадений с регуляркой
        const arrayOfMatches = schemeString.match(regExp);
        const includedSchemesId = [];

        //если совпадений нет, то массив включенных схем остается пустым, значит внутри схемы нет вложенной схемы
        if (arrayOfMatches && arrayOfMatches.length > 0) {
            //проходимся по кадому совпадению и отделяем id от схемы и пушим в массив includedSchemesId
            arrayOfMatches.forEach((element) => {
                includedSchemesId.push(element.split(' ')[1]);
            });
        }
        /**
         * если с бэка пришла мета с координатами, то мы записываем эти координаты в переменные,
         * иначе мы сами относительно высоты окна и размеров ноды высчитываем коорлинаты ноды
         */
        if (element.frontend_meta && element.frontend_meta?.coordinates) {
            yCoordinate = element.frontend_meta.coordinates.y;
            xCoordinate = element.frontend_meta.coordinates.x;
        } else {
            if (yCoordinate + nodeParams.height + 50 >= maxHeigth) {
                xCoordinate += nodeParams.width + 50;
                yCoordinate = 50;
            } else {
                yCoordinate += nodeParams.height + 50;
            }
        }

        //объект с информацией о кажой схеме
        if (group.length > 0) {
            const itemObject = {
                id: element.id.toString(),
                x: xCoordinate,
                y: yCoordinate,
                schemeName: element.name,
                groupName: group[0].name,
                groupColorId: group[0].index,
                groupMeta: group[0].frontend_meta,
                schemeGroupId: element.group_id,
                alias: element.alias,
                scheme: element,
                examples,
                width: nodeParams.width,
                height: nodeParams.height,
                includedSchemesId,
            };
            arraOfConvasDiagramItems.push(itemObject);
        }
    });
    return arraOfConvasDiagramItems;
};

export default setConvasDiagramItems;
