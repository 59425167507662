import { useEffect, useMemo, useRef, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MonacoEditor from 'react-monaco-editor';
import checkLineForBrackets from '../../../utils/monacoUtils/checkLineForBrackets';
import getRangesFromEditor from '../../../utils/monacoUtils/getRangesFromEditor';
import getValuesFromEditor from '../../../utils/monacoUtils/getValuesFromEditor';
import { CustomButton } from '../../schemeEditorMenu/components/codeEditor-styles';
import CodeEditorSelect from '../../schemeEditorMenu/components/codeEditorSelect';
import {
    FullScreenEditorMonacoWrapper,
    FullSCreenEditorTitle,
    FullScreenSchemeEditorModalWrapper,
} from './fullScreenSchemeEditorModal-styles';
import codeEditorHotKeys from '../../../utils/monacoUtils/codeEditorHotKeys';
import ButtonsForEditor from '../../schemeEditorMenu/components/buttonsForEditor';
import { ButtonsWrapper } from '../confirmModal/confirmModal-styles';

/**
 * Модалка для редактирования схемы в большом окне
 * @param {string} code код в редакторе
 * @param {string} schemeId id схемы
 * @param {function} setCode функция для изменения кода в редакторе
 * @param {boolean} selectButtonDisabled флаг, который отвечает за то, активна / неактивна кнопка добавления селекта
 * @param {function} setSelectButtonDisabled изменяет значения флага для кнопки добавления селекта
 * @param {function} addSchemeSelectHandler функция для добавления селекта в код редактора
 * @param {array} schemes массив схем
 */
const FullScreenSchemeEditorModal = ({
    code,
    schemeId,
    setCode,
    selectButtonDisabled,
    setSelectButtonDisabled,
    addSchemeSelectHandler,
    schemes,
    groupSelectData,
    schemeName,
    schemeAlias,
    setSchemeName,
    setSchemeAlias,
    setGroupSelectData,
    closeFullScreenEditor,
}) => {
    const [ranges, setRanges] = useState([]);
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [editorValues, setEditorValues] = useState([]);
    const editorRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        codeEditorHotKeys(editorRef.current.editor, schemes);
    }, [editorRef, schemes]);

    const options = {
        selectOnLineNumbers: true,
        automaticLayout: true,
        lineHeight: 19,
        fontSize: 14,
        fontFamily: 'Monaco, monospace',
        readOnly: false,
    };

    const selectorsArr = useMemo(() => {
        return ranges.map((item) => (
            <CodeEditorSelect
                key={item.startLineNumber - Math.random()}
                startLine={item.startLineNumber}
                startColumn={item.startColumn}
                endColumn={item.endColumn}
                scrollTop={scrollTop}
                schemeId={schemeId}
                editorValues={editorValues}
                editorRef={editorRef}
                scrollLeft={scrollLeft}
            />
        ));
    }, [ranges, scrollTop, editorValues, scrollLeft, schemeId]);

    const editorDidMount = (editor) => {
        const ranges = getRangesFromEditor(editor);
        setRanges(ranges);
        const values = getValuesFromEditor(editor);
        setEditorValues(values);

        editor.getModel().onDidChangeContent(() => {
            const code = editor.getValue();
            setCode(code);
            const ranges = getRangesFromEditor(editor);
            setRanges(ranges);
            const values = getValuesFromEditor(editor);
            setEditorValues(values);
        });

        editor.onDidScrollChange((e) => {
            const ranges = getRangesFromEditor(editor);
            setRanges(ranges);
            setScrollTop(e.scrollTop);
            setScrollLeft(e.scrollLeft);
        });

        editor.onDidChangeCursorPosition(() => {
            const selection = editor.getSelection();
            const lineValue = editor.getModel().getLineContent(selection.startLineNumber);

            if (checkLineForBrackets(lineValue, selection.startColumn - 1)) {
                setSelectButtonDisabled(false);
            } else {
                setSelectButtonDisabled(true);
            }
        });
    };

    return (
        <FullScreenSchemeEditorModalWrapper>
            <FullSCreenEditorTitle>{t('Scheme editor')}</FullSCreenEditorTitle>
            <div>
                <Tooltip title='Ctrl + D / Cmd + D'>
                    <span>
                        <CustomButton
                            onClick={addSchemeSelectHandler(editorRef)}
                            disabled={selectButtonDisabled}
                            variant='outlined'
                            color='primary'
                        >
                            {t('Add existing schema')}
                        </CustomButton>
                    </span>
                </Tooltip>
            </div>
            <FullScreenEditorMonacoWrapper boxShadow={2}>
                <MonacoEditor
                    language='json'
                    theme='vs'
                    editorDidMount={editorDidMount}
                    value={code}
                    options={options}
                    ref={editorRef}
                />
                {selectorsArr}
            </FullScreenEditorMonacoWrapper>
            <ButtonsWrapper>
                <ButtonsForEditor
                    groupSelectData={groupSelectData}
                    schemeName={schemeName}
                    schemeAlias={schemeAlias}
                    schemeId={schemeId}
                    code={code}
                    setCode={setCode}
                    setSchemeName={setSchemeName}
                    setSchemeAlias={setSchemeAlias}
                    setGroupSelectData={setGroupSelectData}
                    showDeleteButton={false}
                    marginTop={10}
                    closeFullScreenEditor={closeFullScreenEditor}
                />
            </ButtonsWrapper>
        </FullScreenSchemeEditorModalWrapper>
    );
};

export default FullScreenSchemeEditorModal;
