import { Box, Button } from '@material-ui/core';
import styled from 'styled-components/macro';

/**
 * стили для компонента buildedSchemeModal
 */

export const ModalWrapper = styled.div`
    outline: 0;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    width: 60%;
    border-radius: 5px;
    height: 95%;
    padding: 10px;
    display: flex;
    flex-direction: column;
`;

export const BuildedSchemeTitle = styled.div`
    text-align: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
`;

export const BuildedSchemeMonacoWrapper = styled(Box)`
    width: 100%;
    height: ${(props) => (props.type === 'addExample' ? '80%' : '90%')};
    margin-top: 10px;
    flex-grow: 1;
`;

export const CustomButton = styled(Button)`
    text-transform: none;
    font-size: 14px;
    max-width: ${(props) => (props.type === 'addExample' ? '80px' : null)};
    margin-top: ${(props) => (props.newcolor ? '10px' : 0)};
    color: ${(props) => (props.newcolor ? props.newcolor : null)};
    border: ${(props) => (props.newcolor ? `1px solid ${props.newcolor}` : null)};
`;

export const PositiveWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const PositiveText = styled.div`
    font-size: 14px;
    margin-right: 5px;
`;
