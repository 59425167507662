import { useState } from 'react';
import { NameWrapper, ItemWrapper, ItemCircle, LeftColumn, RightColumn } from './schemeGroupsCardItem-styles';
import edit from '../../../assets/icons/edit.svg';
import trash from '../../../assets/icons/delete.svg';
import GroupsDeleteConfirm from '../../modals/confirmModal/groupsDeleteConfirm';
import ModalContainer from '../../modals/modalContainer';
import { Trash } from '../../modals/confirmModal/confirmModal-styles';
import { Edit } from '../../modals/editModal/editModal-styles';
import GroupEditModal from '../../modals/editModal/groupEditModal';
import useGroupColor from '../tools/useGroupColor';

/**
 * компонент для отображения группы
 * @param {string} name название группы
 * @param {boolean} arrow если стрелка true, то карточка открыта, false - закрыта
 * @param {number} colorIndex индекс цвета в масиве цветов. если -1, то параметр не передан, занчит гурпп нет
 * @param {object} meta в нем есть поле group_color, которое содержит информацию о цвете
 * @param {number} groupId id группы
 */
const SchemeGroupsCardItem = ({ name = 'No groups', arrow, colorIndex = -1, meta, groupId }) => {
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const color = useGroupColor({ meta, colorIndex, groupId });

    //открытие модалки для удаления
    const handleOpen = () => {
        setOpen(true);
    };

    //закрытие модалки для удаления
    const handleClose = () => {
        setOpen(false);
    };

    //открытие модалки для редактирования
    const handleEditOpen = () => {
        setEditOpen(true);
    };
    //закрытие модалки для удаления
    const handleEditClose = () => {
        setEditOpen(false);
    };

    return (
        <>
            <ModalContainer handleClose={handleEditClose} open={editOpen}>
                <GroupEditModal groupId={groupId} previousGroupName={name} handleClose={handleEditClose} />
            </ModalContainer>
            <ModalContainer handleClose={handleClose} open={open}>
                <GroupsDeleteConfirm handleClose={handleClose} groupId={groupId} />
            </ModalContainer>
            <ItemWrapper arrow={arrow}>
                <LeftColumn>
                    <NameWrapper>{name}</NameWrapper>
                    {colorIndex >= 0 && <ItemCircle color={color} />}
                </LeftColumn>
                {name === 'No groups' ? null : (
                    <RightColumn>
                        <Edit onClick={handleEditOpen} src={edit} alt='edit group' />
                        <Trash width='15px' onClick={handleOpen} src={trash} alt='trash can' />
                    </RightColumn>
                )}
            </ItemWrapper>
        </>
    );
};
export default SchemeGroupsCardItem;
