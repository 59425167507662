import { InputLabel, Select } from '@material-ui/core';
import styled from 'styled-components/macro';

/**
 * стили для компонента headerSelect.jsx
 */

export const CustomSelect = styled(Select)`
    width: 200px;
    margin-left: 15px;
    color: #ffffff;
    ::before {
        border-color: #ffffff;
    }
    ::after {
        border-color: #ffffff;
    }
    svg {
        fill: #ffffff;
    }
`;

export const HeaderSelectWrapper = styled.div`
    margin-right: 40px;
    display: flex;
    align-items: center;
`;

export const VersionMenuItem = styled.div`
    display: flex;
    font-size: 14px;
`;

export const Version = styled.div`
    margin-right: 10px;
`;

export const SourceMenuItem = styled.div`
    font-size: 14px;
`;

export const CustomInputLabel = styled(InputLabel)`
    font-size: 14px;
    color: #ffffff;
`;
