import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from './translations/translations';

/**
 * языки для переводы, translations - словарь
 */
const resources = {
  ru: {
    translation: translations,
  },
};
/**
 * реализация перевода с помощью библиотеки react-i18next
 */
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'eng',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
