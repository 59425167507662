/**
 * Функция для получения +- централизованных координат (это позволит найти схемы, если их не будет)
 * @param {array} convasDiagramItems массив всех схем
 * @param {number} scale скейл стейджа
 * @param {number} stageWidth ширина стейджа
 * @param {number} stageHeight высота
 * @returns объект с координатами центра (+-)
 */
const stageCentering = (convasDiagramItems, scale, stageWidth, stageHeight) => {
    const polygon = convasDiagramItems.map((element) => [element.x, element.y]);

    let summXCoords = 0;
    let summYCoords = 0;
    polygon.forEach((item) => {
        summXCoords = summXCoords + item[0];
        summYCoords = summYCoords + item[1];
    });

    /**
     * centerX, centerY - это координаты центра получившегося полигона
     */
    const centerX = ((summXCoords / polygon.length) * 100) / 100;
    const centerY = ((summYCoords / polygon.length) * 100) / 100;

    const x = -centerX * scale + stageWidth / 2;
    const y = -centerY * scale + stageHeight / 2;
    return {
        x,
        y,
    };
};

export default stageCentering;
