export const ActionsTypes = {
    GET_SCHEME_SOURCES: 'GET_SCHEME_SOURCES',
    GET_SCHEME_VERSIONS: 'GET_SCHEME_VERSIONS',
    GET_GROUPS_FOR_SOURCE: 'GET_GROUPS_FOR_SOURCE',
    SET_NEW_LANG: 'SET_NEW_LANG',
    GET_SCHEMES_ARRAY: 'GET_SCHEMES_ARRAY',
    GET_WINDOW_DIMENSIONS: 'GET_WINDOW_DIMENSIONS',
    SET_SCHEME_EDITOR: 'SET_SCHEME_EDITOR',
    SET_SELECTED_SCHEME: 'SET_SELECTED_SCHEME',
    GET_EXAMPLES_FOR_VERSION: 'GET_EXAMPLES_FOR_VERSION',
    SET_SELECTED_SOURCE: 'SET_SELECTED_SOURCE',
    SET_VERSION_SELECTED: 'SET_VERSION_SELECTED',
    SET_CONVAS_DIAGRAM_ITEMS: 'SET_CONVAS_DIAGRAM_ITEMS',
    CHANGE_NODE_HEIGHT: 'CHANGE_NODE_HEIGHT',
    UPDATE_EXAMPLES_FOR_SELECTED_SCHEME: 'UPDATE_EXAMPLES_FOR_SELECTED_SCHEME',
    SET_SCHEME_NAME_VALIDATE_ERROR: 'SET_SCHEME_NAME_VALIDATE_ERROR',
    SET_SCHEME_GROUP_VALIDATE_ERROR: 'SET_SCHEME_GROUP_VALIDATE_ERROR',
    SET_VERSION_INFO: 'SET_VERSION_INFO',
    GET_CONSUMERS_FOR_SOURCE: 'GET_CONSUMERS_FOR_SOURCE',
    SET_INFO_FOR_ALIAS_COPY: 'SET_INFO_FOR_ALIAS_COPY',
    SET_MONACO_CODE_EQUAL_TO_STATE_CODE: 'SET_MONACO_CODE_EQUAL_TO_STATE_CODE',
    SET_STAGE_CURSOR_POSITION: 'SET_STAGE_CURSOR_POSITION',
    SET_EXAMPLES_FOR_SAVE: 'SET_EXAMPLES_FOR_SAVE',
    RESET_STATE_AFTER_SOURCE_CHANGED: 'RESET_STATE_AFTER_SOURCE_CHANGED',
    RESET_STATE_AFTER_VERSION_CHANGED: 'RESET_STATE_AFTER_VERSION_CHANGED',
};

/**
 * Функция для обнуления необходимых состояний изменения версиями
 */
const resetStateAfterVersionChanged = (state) => {
    return {
        ...state,
        examplesForVersion: [],
        schemes: [],
        convasDiagramItems: [],
        groupsForSource: [],
    };
};

/**
 * функция для обнуления необходимых состояний после изменения исчточника
 */
const resetStateAfterSourceChanged = (state) => {
    return {
        ...state,
        versionInfo: null,
        schemes: [],
        schemeEditor: false,
        schemeVersions: [],
        groupsForSource: [],
        examplesForVersion: [],
        isVesrionSelected: false,
    };
};

/**
 * поучение источников
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит массив источников
 */
const getSchemeSources = (state, action) => {
    return {
        ...state,
        schemaSources: action.payload,
    };
};

/**
 * получение версий для конкретного источника
 * @param {object} state стейт нашего приложения
 * @param {object} action  в payload приходит массив версий для конкретного источника
 */
const getSchemeVersions = (state, action) => {
    return {
        ...state,
        schemeVersions: action.payload,
    };
};

/**
 * получение групп для выбранного источника
 * index необходим для выбора цвета из массива цветов
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит массив групп для выбранного источника
 */
const getGroupsForSource = (state, action) => {
    if (action.payload.length > 0) {
        return {
            ...state,
            groupsForSource: action.payload.map((element, index) => ({ ...element, index })),
        };
    }
    return {
        ...state,
        groupsForSource: [],
    };
};

/**
 * перевод приложения с английского на русский, с русского на английский
 * @param {object} state стейт нашего приложения
 */
const setNewLang = (state) => {
    let newLang = '';
    if (state.lang === 'eng') {
        newLang = 'ru';
    } else {
        newLang = 'eng';
    }
    return {
        ...state,
        lang: newLang,
    };
};

/**
 * получение схем для конкретной версии
 * @param {object} state стейт нашего приложения
 * @param {object} action  в payload приходит массива схем для выбранной версии
 */
const getSchemesArray = (state, action) => {
    return {
        ...state,
        schemes: action.payload,
    };
};

/**
 * получение размеров окна
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит объект {width, heigth}
 */
const getWindowDimensions = (state, action) => {
    return {
        ...state,
        windowDimensions: action.payload,
    };
};

/**
 * устанавливает занчаение true/false: true - виден редактор, false - не виден
 * @param {object} state стейт нашего приложения
 */
const setSchemesEditor = (state, action) => {
    if (action.payload) {
        return {
            ...state,
            schemeEditor: true,
        };
    } else {
        return {
            ...state,
            schemeEditor: action.payload,
            selectedScheme: {},
        };
    }
};

/**
 * функция, которая помещает в стейт выбранную схему
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит объект с выбраной схемой
 */
const setSelectedScheme = (state, action) => {
    return {
        ...state,
        selectedScheme: action.payload,
    };
};

/**
 * функция, которая помещает массив всех примеров для выбранной версии
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит массива объектов, где каждый объект - это пример для схемы
 */
const getExamplesForVersion = (state, action) => {
    return {
        ...state,
        examplesForVersion: action.payload,
    };
};

/**
 * функция которая записывает в стейт информацию о выбранном источнике {id: number, alias: string}
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит объект, в нем информация о выбранном источнике
 */
const setSelectedSource = (state, action) => {
    return {
        ...state,
        selectedSource: action.payload,
    };
};

/**
 * функция устанавливает флаг выбранна версия или нет
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит true, если версия выбранна, false- не выбрана
 */
const setVersionSelected = (state, action) => {
    return {
        ...state,
        isVesrionSelected: action.payload,
    };
};

/**
 * функкци, которая записывает в стейт массив объектов, необходимых для отрисовки диаграммы
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит массив объектов, где каждый объект
 * это информация для каждой схемы
 */
const setConvasDiagramItems = (state, action) => {
    return {
        ...state,
        convasDiagramItems: action.payload,
    };
};

/**
 * функция для изменения высоты ноды
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит объект {id: number, heigth: number} id - id схемы, height -
 * новая высота для схемы
 */
const changeNodeHeight = (state, action) => {
    const newArray = [];
    state.convasDiagramItems.forEach((element) => {
        if (element.id === action.payload.id) {
            const newElement = {
                ...element,
                height: action.payload.height,
            };
            newArray.push(newElement);
        } else {
            newArray.push(element);
        }
    });
    return {
        ...state,
        convasDiagramItems: newArray,
    };
};

/**
 * функция для обновленя массива примеров для выбранной схемы
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит новый массив примеров для выбранной схемы
 */
const updateExamplesForSelectedScheme = (state, action) => {
    return {
        ...state,
        selectedScheme: {
            ...state.selectedScheme,
            examples: action.payload,
        },
    };
};

/**
 * функция устанавливает значение валидации имени
 * если true - то ошибка, false - ошибки нет
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит boolean знычение true/false
 */
const setSchemeNameValidateError = (state, action) => {
    return {
        ...state,
        schemeNameValidateError: action.payload,
    };
};

/**
 * функция утсанавливает значение валидации групп,
 * если true - то есть ошибка, false - нет ошибки
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит boolean значение true/false
 * @returns
 */
const setSchemeGroupValidateError = (state, action) => {
    return {
        ...state,
        schemeGroupValidateError: action.payload,
    };
};

/**
 * функция устанавливает информацию о вбранной версии
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит объект {id: number, version: number, stable: string}
 * @returns
 */
const setVersionInfo = (state, action) => {
    return {
        ...state,
        versionInfo: action.payload,
    };
};

/**
 * функция получает и записываем в стейт массив всех потребителей
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит массив всех потребителей
 * @returns
 */
const getConsumersForSource = (state, action) => {
    return {
        ...state,
        consumersForSource: action.payload,
    };
};

/**
 *
 * @param {object} state стейт нашего приложения
 * @param {object || {}} action в payload приходит объект {isOpen: boolean, schemeAlias: string}
 */
const setInfoForAliasCopy = (state, action) => {
    return {
        ...state,
        infoForAliasCopy: action.payload,
    };
};

/**
 *
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит boolean значение, которые озночает: true -
 * код из редактора рвен коду в стейте, false - то код в редакторе был изменен
 */
const setMonacoCodeEqualToStateCode = (state, action) => {
    return {
        ...state,
        isMonacoCodeEqualToStateCode: action.payload,
    };
};

/**
 *
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит объект с координатами x и y
 */
const setStageCursorPosition = (state, action) => {
    return {
        ...state,
        stageCusrsorPosition: action.payload,
    };
};

/**
 *
 * @param {object} state стейт нашего приложения
 * @param {object} action в payload приходит объект с информацией о примере
 */
const setExamplesForSave = (state, action) => {
    if (action.payload.reset) {
        return {
            ...state,
            examplesForSave: [],
        };
    } else {
        const clone = state.examplesForSave.slice();
        if (action.payload.add) {
            const index = clone.findIndex((element) => element.id === action.payload.example.id);
            if (index !== -1) {
                clone.splice(index, 1);
                clone.push(action.payload.example);
            } else {
                clone.push(action.payload.example);
            }
        } else {
            const index = clone.findIndex((element) => element.id === action.payload.example.id);
            if (index !== -1) {
                clone.splice(index, 1);
            }
        }
        return {
            ...state,
            examplesForSave: clone,
        };
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionsTypes.RESET_STATE_AFTER_VERSION_CHANGED:
            return resetStateAfterVersionChanged(state);
        case ActionsTypes.RESET_STATE_AFTER_SOURCE_CHANGED:
            return resetStateAfterSourceChanged(state);
        case ActionsTypes.GET_SCHEME_SOURCES:
            return getSchemeSources(state, action);

        case ActionsTypes.GET_SCHEME_VERSIONS:
            return getSchemeVersions(state, action);

        case ActionsTypes.GET_GROUPS_FOR_SOURCE:
            return getGroupsForSource(state, action);

        case ActionsTypes.SET_NEW_LANG:
            return setNewLang(state);

        case ActionsTypes.GET_SCHEMES_ARRAY:
            return getSchemesArray(state, action);

        case ActionsTypes.GET_WINDOW_DIMENSIONS:
            return getWindowDimensions(state, action);

        case ActionsTypes.SET_SCHEME_EDITOR:
            return setSchemesEditor(state, action);

        case ActionsTypes.SET_SELECTED_SCHEME:
            return setSelectedScheme(state, action);

        case ActionsTypes.GET_EXAMPLES_FOR_VERSION:
            return getExamplesForVersion(state, action);

        case ActionsTypes.SET_SELECTED_SOURCE:
            return setSelectedSource(state, action);

        case ActionsTypes.SET_VERSION_SELECTED:
            return setVersionSelected(state, action);

        case ActionsTypes.SET_CONVAS_DIAGRAM_ITEMS:
            return setConvasDiagramItems(state, action);

        case ActionsTypes.CHANGE_NODE_HEIGHT:
            return changeNodeHeight(state, action);

        case ActionsTypes.UPDATE_EXAMPLES_FOR_SELECTED_SCHEME:
            return updateExamplesForSelectedScheme(state, action);

        case ActionsTypes.SET_SCHEME_NAME_VALIDATE_ERROR:
            return setSchemeNameValidateError(state, action);

        case ActionsTypes.SET_SCHEME_GROUP_VALIDATE_ERROR:
            return setSchemeGroupValidateError(state, action);

        case ActionsTypes.SET_VERSION_INFO:
            return setVersionInfo(state, action);

        case ActionsTypes.GET_CONSUMERS_FOR_SOURCE:
            return getConsumersForSource(state, action);

        case ActionsTypes.SET_INFO_FOR_ALIAS_COPY:
            return setInfoForAliasCopy(state, action);

        case ActionsTypes.SET_MONACO_CODE_EQUAL_TO_STATE_CODE:
            return setMonacoCodeEqualToStateCode(state, action);

        case ActionsTypes.SET_STAGE_CURSOR_POSITION:
            return setStageCursorPosition(state, action);

        case ActionsTypes.SET_EXAMPLES_FOR_SAVE:
            return setExamplesForSave(state, action);
        default:
            return state;
    }
};

export default reducer;
