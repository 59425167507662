import { useContext, useEffect } from 'react';
import { getRequest } from '../api/api';
import Context from '../store/context';
import { ActionsTypes } from '../store/reducer';

/**
 * Кастомный хук для получения данных и установки состояний
 */
const useFetchData = () => {
    const {
        state: { selectedSource, urls, versionInfo, schemeEditor, schemeVersions, schemaSources },
        dispatch,
        token,
    } = useContext(Context);

    useEffect(() => {
        try {
            const source = JSON.parse(localStorage.getItem('source'));
            if (source && schemaSources.length > 0) {
                dispatch({
                    type: ActionsTypes.SET_SELECTED_SOURCE,
                    payload: {
                        id: source.id,
                        alias: source.alias,
                    },
                });
            }
        } catch (error) {
            console.log('>>>localStorage sources', error);
        }
    }, [token, schemaSources]);

    useEffect(() => {
        if (selectedSource) {
            localStorage.setItem('source', JSON.stringify({ id: selectedSource.id, alias: selectedSource.alias }));
            dispatch({ type: ActionsTypes.RESET_STATE_AFTER_SOURCE_CHANGED });

            getRequest(
                dispatch,
                `${urls.BASE_URL}versions/?source_id=${selectedSource.id}`,
                {
                    actionName: ActionsTypes.GET_SCHEME_VERSIONS,
                },
                token,
            );

            getRequest(
                dispatch,
                `${urls.BASE_URL}consumers/?source_id=${selectedSource.id}`,
                {
                    actionName: ActionsTypes.GET_CONSUMERS_FOR_SOURCE,
                },
                token,
            );
        }
    }, [selectedSource]);

    useEffect(() => {
        const latestVersion = schemeVersions.reduce((acc, value) => {
            if (!acc) acc = value;
            if (acc && acc.version < value.version) acc = value;
            return acc;
        }, null);
        try {
            const version = JSON.parse(localStorage.getItem('version'));
            if (version && schemeVersions.find((element) => element.id === version.id)) {
                dispatch({
                    type: ActionsTypes.SET_VERSION_INFO,
                    payload: { id: version.id, version: version.version, stable: version.stable },
                });
            } else if (latestVersion) {
                dispatch({
                    type: ActionsTypes.SET_VERSION_INFO,
                    payload: { id: latestVersion.id, version: latestVersion.version, stable: latestVersion.stable },
                });
            }
        } catch (error) {
            console.log('>>>localStorage version', error);
        }
    }, [schemeVersions]);

    useEffect(() => {
        const asyncFunction = async () => {
            if (versionInfo) {
                localStorage.setItem(
                    'version',
                    JSON.stringify({
                        version: versionInfo.version,
                        stable: versionInfo.stable,
                        id: versionInfo.id,
                    }),
                );
                dispatch({ type: ActionsTypes.RESET_STATE_AFTER_VERSION_CHANGED });
                dispatch({ type: ActionsTypes.SET_VERSION_SELECTED, payload: true });

                getRequest(
                    dispatch,
                    `${urls.BASE_URL}groups/?version_id=${versionInfo.id}`,
                    {
                        actionName: ActionsTypes.GET_GROUPS_FOR_SOURCE,
                    },
                    token,
                );

                const schemes = await getRequest(
                    dispatch,
                    `${urls.BASE_URL}schemas/?version_id=${versionInfo.id}`,
                    {
                        actionName: ActionsTypes.GET_SCHEMES_ARRAY,
                    },
                    token,
                );

                if (schemes !== 'error') {
                    await getRequest(
                        dispatch,
                        `${urls.BASE_URL}examples/?version_id=${versionInfo.id}`,
                        {
                            actionName: ActionsTypes.GET_EXAMPLES_FOR_VERSION,
                        },
                        token,
                    );
                }

                if (schemeEditor) {
                    dispatch({ type: ActionsTypes.SET_SCHEME_EDITOR, payload: false });
                }
            }
        };

        asyncFunction();
    }, [versionInfo]);
};

export default useFetchData;
