import { Button } from '@material-ui/core';
import styled from 'styled-components/macro';

/**
 * стили для компонента schemeArea.jsx
 */

export const SchemeAreaConteiner = styled.div`
    flex-grow: 1;
    width: 100%;
    position: relative;
`;

export const SchemeAreaWrapper = styled.div`
    box-sizing: border-box;
    height: 100%;
    background-color: f8fafc;
`;

export const StageWrapper = styled.div`
    width: 100%;
    height: 100%;
    border: 0.07rem solid #dae1e7;
    box-shadow: 0 0.8rem 1rem -0.2rem rgb(0 0 0 / 10%), 0 0.25rem 0.5rem -0.02rem rgb(0 0 0 / 5%);
    position: relative;
`;

export const CenteringButton = styled(Button)`
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    z-index: 5;
    text-transform: none;
`;
