import findDifferenceBetweenArray from './findDeferenceBetweenArrays';

/**
 * фунция возвращает массив допустимых схем для выбора в селекте
 * @param {array} schemes массив всех схем
 * @param {array} arrayOfSchemeIds массив уже зависящих схем (массив их id)
 * @returns
 */
const createArrayofValidShemes = (schemes, arrayOfSchemeIds) => {
	let newSchemeIdsArray = [];

	schemes.forEach((scheme) => {
		scheme.included_schemas.forEach((id) => {
			if (arrayOfSchemeIds.includes(id) && !arrayOfSchemeIds.includes(scheme.id)) {
				newSchemeIdsArray.push(scheme.id);
			}
		});
	});

	if (newSchemeIdsArray.length === 0) {
		return findDifferenceBetweenArray(schemes, arrayOfSchemeIds);
	}

	return createArrayofValidShemes(schemes, [...arrayOfSchemeIds, ...newSchemeIdsArray]);
};

export default createArrayofValidShemes;
