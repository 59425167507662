import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRequest, patchRequest, postRequest } from '../../../api/api';
import Context from '../../../store/context';
import { ActionsTypes } from '../../../store/reducer';
import codeTextTransformer from '../../../utils/monacoUtils/codeTextTransformer';
import schemePatchHelper from '../../../utils/requestHelpers/schemePatchHelper';
import schemePostHelper from '../../../utils/requestHelpers/schemePostHelper';
import SchemeDeleteConfirm from '../../modals/confirmModal/schemeDeleteConfirm';
import ModalContainer from '../../modals/modalContainer';
import { ButtonsWrapper, CustomButton } from './buttonsForEditor-styles';

/**
 * компонент для отрисовки кнопок: удаления, создания, обновления и отмены изменений для выбранной схемы
 * @param {string || null} schemeAlias описание для схемы
 * @param {string} schemeName название схемы
 * @param {number} groupSelectData id группы
 * @param {string} code код редакторв в виде строки
 * @param {string} schemeId id схемы в виде строки
 * @param {function} setSchemeName функция, которая устанавливает значениу имени в localstate
 * @param {function} setSchemeAlias функция, которая устанавливает значение описания в localstate
 * @param {function} setGroupSelectData функция, которая устанавливает значениe id группы в localstate
 * @param {function} setCode функция, которая устанавливает значениe из редактора  в localstate
 * @param {boolean} showDeleteButton флаг, отвечающий за отображение кнопки удаления
 * @param {number} marginTop отступ сверху
 * @param {function} closeFullScreenEditor закртие большого редактора
 */
const ButtonsForEditor = ({
    schemeAlias,
    schemeName,
    groupSelectData,
    code,
    schemeId,
    setSchemeName,
    setSchemeAlias,
    setGroupSelectData,
    setCode,
    showDeleteButton = true,
    marginTop = 20,
    closeFullScreenEditor = null,
}) => {
    const { state, dispatch, token } = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //сохранение схемы или создание схемы
    const saveSchemeHandler = async () => {
        let jsonCode = '';
        const includedSchemesId = [];
        //tесли не получается превратить строку в JSON , ты выводим диалогове окное с ошибкой
        try {
            jsonCode = JSON.parse(code);
        } catch (error) {
            enqueueSnackbar('Wrong JSON', {
                variant: 'error',
            });
        }

        if (jsonCode) {
            //регулярка для поиска "__schema__": id
            const regExp = /"__schema__": \S*?(?=})/gm;
            //получение массива всех совпадений с регуляркой
            const arrayOfMatches = code.match(regExp);

            //если совпадений нет, то массив включенных схем остается пустым, значит внутри схемы нет вложенной схемы
            if (arrayOfMatches && arrayOfMatches.length > 0) {
                //проходимся по кадому совпадению и отделяем id от схемы и пушим в массив includedSchemesId
                arrayOfMatches.forEach((element) => {
                    includedSchemesId.push(+element.split(' ')[1]);
                });
            }
        }

        //если при клике поле Name было пусто,то диспатчим ошибку
        if (!schemeName) dispatch({ type: ActionsTypes.SET_SCHEME_NAME_VALIDATE_ERROR, payload: true });

        //если при клике поле Groups было пусто, то диспатчим ошибку
        if (!groupSelectData) dispatch({ type: ActionsTypes.SET_SCHEME_GROUP_VALIDATE_ERROR, payload: true });

        /**
         * если условие выполняется, ты деелаем Post запрос для создания схемы
         * так как если schemeId не передано, значит такой схемы нет и мы делаем post, а не patch
         */
        if (schemeName && groupSelectData && jsonCode && !schemeId) {
            schemePostHelper({
                dispatch,
                versionInfo: state.versionInfo,
                groupSelectData,
                schemeAlias,
                schemeName,
                jsonCode,
                includedSchemesId,
                stageCusrsorPosition: state.stageCusrsorPosition,
                BASE_URL: state.urls.BASE_URL,
                token,
                ActionsTypes,
                setCode,
                enqueueSnackbar,
                getRequest,
                postRequest,
                closeFullScreenEditor,
            });
        } else {
            if (schemeName && groupSelectData && jsonCode) {
                schemePatchHelper({
                    dispatch,
                    groupSelectData,
                    schemeAlias,
                    schemeName,
                    jsonCode,
                    includedSchemesId,
                    examplesForSave: state.examplesForSave,
                    token,
                    BASE_URL: state.urls.BASE_URL,
                    ActionsTypes,
                    schemeId,
                    versionInfo: state.versionInfo,
                    getRequest,
                    enqueueSnackbar,
                    patchRequest,
                    closeFullScreenEditor,
                });
            }
        }
    };

    /**
     * возврат к исходной схеме, эта кнопка активна, если схем есть в массиве схем,
     * то есть эта кнопка не активна при создании новой схемы
     */
    const cancelAllChanges = () => {
        setSchemeName(state.selectedScheme.schemeName);

        if (state.selectedScheme.schemeAlias === null) {
            setSchemeAlias('');
        } else {
            setSchemeAlias(state.selectedScheme.schemeAlias);
        }

        setGroupSelectData(state.selectedScheme.schemeGroupId);

        if (state.selectedScheme.shortScheme) {
            setCode(codeTextTransformer(state.selectedScheme.shortScheme));
        } else {
            setCode('');
        }
    };

    return (
        <>
            <ModalContainer open={open} handleClose={handleClose}>
                <SchemeDeleteConfirm schemeId={schemeId} handleClose={handleClose} />
            </ModalContainer>
            <ButtonsWrapper marginTop={marginTop}>
                <CustomButton
                    disabled={state.versionInfo?.stable}
                    onClick={saveSchemeHandler}
                    variant='outlined'
                    newcolor='#4caf50'
                >
                    {t('Save')}
                </CustomButton>
                <CustomButton
                    disabled={!schemeId || state.versionInfo?.stable}
                    onClick={cancelAllChanges}
                    variant='outlined'
                    newcolor='#ff9800'
                >
                    {t('Cancel')}
                </CustomButton>
                {showDeleteButton && (
                    <CustomButton
                        disabled={!schemeId || state.versionInfo?.stable}
                        onClick={handleOpen}
                        variant='outlined'
                        newcolor='	#ff0000'
                    >
                        {t('Delete')}
                    </CustomButton>
                )}

                {/* <ConfirmModal schemeId={schemeId} type="scheme" /> */}
            </ButtonsWrapper>
        </>
    );
};

export default ButtonsForEditor;
