// import { makeStyles, styled } from '@material-ui/core';
import styled from 'styled-components/macro';

/**
 * стили для компонента schemeGroupsCardItem.jsx
 */

export const NameWrapper = styled.div`
    text-align: left;
    font-size: 14px;
    margin-right: 10px;
`;

export const ItemWrapper = styled.div`
    transition: 0.5s all;
    margin-top: ${(props) => (props.arrow ? '10px' : '0')};
    height: ${(props) => (props.arrow ? '20px' : '0px')};
    display: flex;
    opacity: ${(props) => (props.arrow ? '1' : '0')};
    align-items: center;
    justify-content: space-between;
`;

export const ItemCircle = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
`;

export const LeftColumn = styled.div`
    display: flex;
    align-items: center;
`;

export const Trash = styled.img`
    width: 15px;
`;
export const RightColumn = styled.div`
    display: flex;
    align-items: center;
`;
