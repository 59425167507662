import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@material-ui/core';
import styled from 'styled-components/macro';

export const CustomAccordion = styled(Accordion)`
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: none;
    :not(::last-child) {
        border-bottom: 0;
    }

    ::before {
        display: none;
    }

    :expanded {
        margin: 'auto';
    }
`;

export const CustomAccordionSummary = styled(AccordionSummary)`
    background-color: ${(props) => (props.color ? props.color : 'rgba(0, 0, 0, .03)')};
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: -1px;
    min-height: 56px;

    :expanded {
        min-height: 56px;
    }
`;

export const CustomAccordionDetails = styled(AccordionDetails)`
    flex-direction: column;
    padding: 0;
`;

export const CustomTextField = styled(TextField)`
    width: 300px;

    margin: 10px 0 10px 16px;
    div {
        height: 30px;
    }
    input {
        height: 30px;
    }
    label {
        transform: translate(13px, 8px) scale(1);
    }
`;
