import { Switch, Tooltip } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import MonacoEditor from 'react-monaco-editor';
import trash from '../../../assets/icons/delete.svg';
import {
    SingleExampleWrapper,
    InformationWrapper,
    ValidText,
    PositiveWrapper,
    PositiveText,
    SingleExampleEditorWrapper,
    ErrorText,
    FloppyDisk,
    CodeNotEqual,
    PositiveTextWrapper,
    NegativeTextWrapper,
} from './codeEditorSingleExample-styles';
import floppyDisk from '../../../assets/icons/floppy-disk.svg';
import { patchRequest } from '../../../api/api';
import Context from '../../../store/context';
import { useSnackbar } from 'notistack';
import { ActionsTypes } from '../../../store/reducer';
import { Trash } from '../../modals/confirmModal/confirmModal-styles';
import ModalContainer from '../../modals/modalContainer';
import ExamplesDeleteConfirm from '../../modals/confirmModal/examplesDeleteConfirm';
import exampleHelper from '../../../utils/exampleHelper';

/**
 * компонент для отрисовки одного примера для конкретной схемы
 * @param {boolean} positive правильность примера
 * @param {boolean} valid правильность примера
 * @param {object} example сам прмеир в формате JSON
 * @param {null || string} error ошибка, если есть, то строка, если ее нет, то null
 * @param {number} id id примера
 */
const CodeEditorSingleExample = ({ positive, valid, example, error, id }) => {
    const { state, dispatch, token } = useContext(Context);
    const [code, setCode] = useState(JSON.stringify(example, null, 2));
    const [switchValue, setSwitchValue] = useState(positive);
    const [equal, setEqual] = useState(true);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        if (state.isMonacoCodeEqualToStateCode) {
            setOpen(true);
        }
    };

    /**
     * вызывается при изменении кода или при изменении начального примера,
     * если код редактора равен изначальному примеру, то ставим флаг в true,
     * иначе в false
     */
    useEffect(() => {
        if (code === JSON.stringify(example, null, 2) && positive === switchValue) {
            exampleHelper({
                flag: true,
                type: ActionsTypes.SET_EXAMPLES_FOR_SAVE,
                dispatch,
                code,
                switchValue,
                id,
                setEqual,
            });
        } else {
            exampleHelper({
                flag: false,
                type: ActionsTypes.SET_EXAMPLES_FOR_SAVE,
                dispatch,
                code,
                switchValue,
                id,
                setEqual,
            });
        }
    }, [code, example, switchValue, positive]);

    /**
     * обработчик нажатия на иконку дискеты сохранения
     * если запрос на сервер проше л успешно, то диспатчим новый массива всех примеров и диспатчим новый
     * примеров для выбранной схемы
     */
    const floppyDiskHandler = async () => {
        try {
            //превращаем код редактора в JSON, если это не получилось, то выводим дологовое окно с ошибкой
            const requestBody = {
                example: JSON.parse(code),
                positive: switchValue,
            };
            //ищем индекс выбранного примера для выбранной схемы
            const index = state.selectedScheme.examples.findIndex((item) => item.id === id);
            //делаем копию массива примеров для выбранной схемы
            const examplesCopy = state.selectedScheme.examples.slice();

            //ищем индекс выбранного примера для массива всех примеров
            const index2 = state.examplesForVersion.findIndex((item) => item.id === id);
            //делаем копию массива примеров для массива всех примеров
            const examplesCopy2 = state.examplesForVersion.slice();

            const data = await patchRequest(`${state.urls.BASE_URL}examples/${id}/`, requestBody, token);
            if (!data.isError) {
                examplesCopy[index] = data;
                examplesCopy2[index2] = data;
                enqueueSnackbar('Example has been updated', {
                    variant: 'success',
                });

                dispatch({
                    type: ActionsTypes.GET_EXAMPLES_FOR_VERSION,
                    payload: examplesCopy2,
                });
                dispatch({
                    type: ActionsTypes.UPDATE_EXAMPLES_FOR_SELECTED_SCHEME,
                    payload: examplesCopy,
                });
                setEqual(true);
            } else {
                const errorKey = Object.keys(data.error.data)[0];
                if (errorKey) {
                    enqueueSnackbar(data.error.data[errorKey], {
                        variant: 'error',
                    });
                } else {
                    enqueueSnackbar('Server error', {
                        variant: 'error',
                    });
                }
            }
        } catch (error) {
            enqueueSnackbar('Wrong JSON', {
                variant: 'error',
            });
        }
    };

    const positiveSwitchHandler = () => {
        if (!state.versionInfo?.stable && state.isMonacoCodeEqualToStateCode) {
            setSwitchValue((prevState) => !prevState);
        }
    };

    //настрокий для монако
    const options = {
        selectOnLineNumbers: true,
        lineHeight: 19,
        automaticLayout: true,
        fontSize: 14,
        fontFamily: 'Monaco, monospace',
        readOnly: state.versionInfo?.stable || !state.isMonacoCodeEqualToStateCode,
    };

    //редактор вмонтировался на страницу
    const editorDidMount = (editor) => {
        //отслеживание изменения кода
        editor.getModel().onDidChangeContent(() => {
            setCode(() => editor.getValue());
        });
    };

    return (
        <>
            <ModalContainer open={open} handleClose={handleClose}>
                <ExamplesDeleteConfirm handleClose={handleClose} exampleId={id} />
            </ModalContainer>
            <SingleExampleWrapper>
                <CodeNotEqual isEqual={!state.isMonacoCodeEqualToStateCode} />
                <InformationWrapper>
                    <div>
                        <ValidText valid={valid}>{valid ? 'Success' : 'Failure'}</ValidText>
                    </div>
                    <PositiveWrapper>
                        <PositiveText>
                            {' '}
                            <NegativeTextWrapper switchValue={switchValue}>Negative</NegativeTextWrapper> /{' '}
                            <PositiveTextWrapper switchValue={switchValue}>Positive</PositiveTextWrapper>
                        </PositiveText>
                        {/* <PositiveText>{switchValue ? 'Positive' : 'Negative'}</PositiveText> */}
                        <Switch color='primary' onChange={positiveSwitchHandler} checked={switchValue} />
                        {!equal && (
                            <Tooltip placement='top' title='Save changes'>
                                <FloppyDisk onClick={floppyDiskHandler} src={floppyDisk} alt='floppy disk' />
                            </Tooltip>
                        )}
                        {state.versionInfo?.stable ? null : (
                            <Tooltip placement='top' title='Delete this example'>
                                <Trash width={'22px'} onClick={handleOpen} src={trash} alt='trash can' />
                            </Tooltip>
                        )}
                    </PositiveWrapper>
                </InformationWrapper>
                <SingleExampleEditorWrapper
                    height={JSON.stringify(example, null, 2).split('\n').length * 19 + 10}
                    boxShadow={1}
                >
                    <MonacoEditor
                        language='json'
                        theme='vs'
                        value={code}
                        options={options}
                        editorDidMount={editorDidMount}
                    />
                </SingleExampleEditorWrapper>
                {error ? <ErrorText>{error}</ErrorText> : null}
            </SingleExampleWrapper>
        </>
    );
};

export default CodeEditorSingleExample;
