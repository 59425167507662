import { useState } from 'react';
import edit from '../../../assets/icons/edit.svg';
import { SingleConsumerText, SingleConsumerWrapper, ModalsWrapper } from './singleConsumer-styles';
import trash from '../../../assets/icons/delete.svg';
import ModalContainer from '../../modals/modalContainer';
import ConsumersDeleteConfirm from '../../modals/confirmModal/consumersDeleteConfirm';
import { Trash } from '../../modals/confirmModal/confirmModal-styles';
import ConsumerEditModal from '../../modals/editModal/consumerEditModal';
import { Edit } from '../../modals/editModal/editModal-styles';
/**
 * отображение каждого отдельного потребителя
 * @param {string} token имя потребителя
 * @param {boolean} arrow значение плашки: открыта или закрыта
 * @param {number} id id консьюмера
 */
const SingleConsumer = ({ token, arrow, id }) => {
	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);

	//открытие модалки для удаления
	const handleOpen = () => {
		setOpen(true);
	};

	//закрытие модалки для удаления
	const handleClose = () => {
		setOpen(false);
	};

	//открытие модалки для редактирования
	const handleEditOpen = () => {
		setEditOpen(true);
	};
	//закрытие модалки для удаления
	const handleEditClose = () => {
		setEditOpen(false);
	};
	return (
		<>
			<ModalContainer handleClose={handleEditClose} open={editOpen}>
				<ConsumerEditModal consumerId={id} previousConsumerToken={token} />
			</ModalContainer>
			<ModalContainer open={open} handleClose={handleClose}>
				<ConsumersDeleteConfirm handleClose={handleClose} consumerId={id} />
			</ModalContainer>
			<SingleConsumerWrapper arrow={arrow}>
				<SingleConsumerText arrow={arrow}>{token}</SingleConsumerText>
				<ModalsWrapper>
					<Edit onClick={handleEditOpen} src={edit} alt="edit group" />
					<Trash width="15px" onClick={handleOpen} src={trash} alt="trash can" />
				</ModalsWrapper>
			</SingleConsumerWrapper>
		</>
	);
};
export default SingleConsumer;
