import { Box } from '@material-ui/core';
import styled from 'styled-components/macro';

/**
 * стили для компонента CodeEditorSingleExample
 */

export const SingleExampleWrapper = styled.div`
	margin-top: 10px;
	position: relative;
`;

export const InformationWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const ValidText = styled.div`
	font-size: 14px;
	color: ${(props) => (props.valid ? '#4caf50' : '#f44336')};
`;

export const PositiveWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const PositiveText = styled.div`
	margin-right: 5px;
	font-size: 14px;
`;

export const SingleExampleEditorWrapper = styled(Box)`
	overflow: hidden;
	resize: vertical;
	padding-bottom: 10px;
	height: ${(props) => props.height};
`;

export const ErrorText = styled.div`
	margin-top: 10px;
	color: #f44336;
	font-size: 14px;
`;

export const FloppyDisk = styled.img`
	width: 22px;
	cursor: pointer;
	margin: 0 5px;
`;

export const CodeNotEqual = styled.div`
	transition: 0.2s all;
	position: absolute;
	top: 3px;
	left: -3px;
	width: ${(props) => (props.isEqual ? '101%' : 0)};
	height: 101%;
	z-index: 100;
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 5px;
	pointer-events: none;
`;

export const PositiveTextWrapper = styled.span`
	padding: 5px;
	border-radius: 5px;
	color: ${(props) => (props.switchValue ? '#ffffff' : 'black')};
	background-color: ${(props) =>
		props.switchValue ? 'rgba(17, 82, 147, 0.5)' : '#ffffff'};
`;

export const NegativeTextWrapper = styled.span`
	padding: 2px;
	border-radius: 5px;
	color: ${(props) => (props.switchValue ? 'black' : '#ffffff')};
	padding: 5px;
	background-color: ${(props) =>
		props.switchValue ? '#ffffff' : 'rgba(17, 82, 147, 0.5)'};
`;
