import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { patchRequest } from '../../../api/api';
import Context from '../../../store/context';
import { ActionsTypes } from '../../../store/reducer';
import {
	ContentWrapper,
	EditTitle,
	PreviousName,
	TextFieldWrapper,
	CustomButton,
	CustomTextField,
} from './editModal-styles';

const ConsumerEditModal = ({ consumerId, previousConsumerToken }) => {
	const { state, dispatch, token } = useContext(Context);
	const [editInputValue, setEditInputValue] = useState('');
	const [error, setError] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const editInputHandler = (e) => {
		setError(false);
		setEditInputValue(e.target.value);
	};

	const editGroupName = async () => {
		if (editInputValue) {
			const requestBody = {
				token: editInputValue,
			};

			//ищем индекс выбранного консьюмера
			const index = state.consumersForSource.findIndex((item) => item.id === consumerId);
			//делаем копию массива консьюмеров
			const consumersCopy = state.consumersForSource.slice();

			//ожидание ответа от сервера
			const data = await patchRequest(`${state.urls.BASE_URL}consumers/${consumerId}/`, requestBody, token);
			/**
			 * если ответ положительный, то показываем диалоговой окно с успехом и
			 * диспатчим новый массив консьюмеров, и закрываем модальное окно
			 */
			if (!data.isError) {
				enqueueSnackbar('Consumer token changed', {
					variant: 'success',
				});
				consumersCopy[index] = data;
				dispatch({ type: ActionsTypes.GET_CONSUMERS_FOR_SOURCE, payload: consumersCopy });
			} else {
				const errorKey = Object.keys(data.error.data)[0];
				if (errorKey) {
					enqueueSnackbar(data.error.data[errorKey], {
						variant: 'error',
					});
				} else {
					enqueueSnackbar('Server error', {
						variant: 'error',
					});
				}
			}
			setEditInputValue('');
		} else {
			setError(true);
		}
	};
	return (
		<ContentWrapper>
			<EditTitle>{t('Consumer eddit')}</EditTitle>
			<PreviousName>
				<span>{`${t('Previous consumer token')}: ${previousConsumerToken}`}</span>
			</PreviousName>
			<TextFieldWrapper>
				<CustomTextField
					value={editInputValue}
					onChange={editInputHandler}
					label={t('New consumer token')}
					helperText={error ? 'This field is required' : null}
					error={error ? true : false}
				/>
			</TextFieldWrapper>
			<CustomButton onClick={editGroupName} newcolor={'green'} variant="outlined">
				{t('Save')}
			</CustomButton>
		</ContentWrapper>
	);
};

export default ConsumerEditModal;
