/**
 * функция позволяет найти отличия между двумя массивами
 * @param {array} array1 массив, который отфильтровать (найти различия со вторым)
 * @param {array} array2 второй массив
 * @returns
 */
const findDifferenceBetweenArray = (array1, array2) => {
	return array1.filter((item) => !array2.includes(item.id));
};
export default findDifferenceBetweenArray;
