import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteRequest } from '../../../api/api';
import Context from '../../../store/context';
import { ActionsTypes } from '../../../store/reducer';
import { ConfirmModalWrapper, ConfirmTitle, ConfirmText, ButtonsWrapper, CustomButton } from './confirmModal-styles';
/**
 * модалка для удаления группы
 * @param {function} handleClose функция для закрытия модалки
 * @param {number} groupId id группы
 * @returns
 */
const GroupsDeleteConfirm = ({ handleClose, groupId }) => {
	const { state, dispatch, token } = useContext(Context);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const groupDeleteHandler = async () => {
		//ожидание ответа от сревера
		const data = await deleteRequest(`${state.urls.BASE_URL}groups/${groupId}/`, token);
		/**
		 * если ответ пришел без ошибки, то показываем диалоговое окно с успехом,
		 * удаляем выбранную группу из массива всех групп
		 */
		if (!data.isError) {
			enqueueSnackbar('Success delete', {
				variant: 'success',
			});
			dispatch({
				type: ActionsTypes.GET_GROUPS_FOR_SOURCE,
				payload: state.groupsForSource.filter((item) => item.id !== groupId),
			});

			//иначе показываем ошибку и закрываем модальное окно
		} else {
			const errorKey = Object.keys(data.error.data)[0];
			if (errorKey) {
				enqueueSnackbar(data.error.data[errorKey], {
					variant: 'error',
				});
			} else {
				enqueueSnackbar('Server error', {
					variant: 'error',
				});
			}
		}
	};

	return (
		<ConfirmModalWrapper>
			<ConfirmTitle>{t('Are you sure?')}</ConfirmTitle>
			<ConfirmText>{t('This will delete this group')}</ConfirmText>
			<ButtonsWrapper>
				<CustomButton onClick={handleClose}>{t('Cancel')}</CustomButton>
				<CustomButton color="primary" onClick={groupDeleteHandler}>
					Ok
				</CustomButton>
			</ButtonsWrapper>
		</ConfirmModalWrapper>
	);
};

export default GroupsDeleteConfirm;
