import { useContext, useEffect, useRef, useState } from 'react';
import Context from '../../store/context';
import { ActionsTypes } from '../../store/reducer';
import SchemeGroupsCard from './components/schemeGroupsCard';
import { CenteringButton, SchemeAreaConteiner, StageWrapper } from './schemeArea-styles';
import { Stage, Layer } from 'react-konva';
import ConvasSingleItem from './components/convasSingleItem';
import ConvasArrows from './components/convasArrows';
import ConsumersCard from './components/consumersCard';
import getNewCoordinatesForMouse from '../../utils/getNewCoordinatesForMouse';
import stageCentering from '../../utils/convasDiagram/stageCentering';
import { useTranslation } from 'react-i18next';

/**
 * обертка для карточки с группами и сети схем
 */
const SchemeArea = () => {
    const { state, dispatch } = useContext(Context);
    const [stageCoordinates, setStageCoordinates] = useState({ stageX: 0, stageY: 0 });
    const [stageScale, setStageScale] = useState(1);
    const stageContainerRef = useRef();
    const { t } = useTranslation();

    /**
     * после того, как компонент вмантировался на страницу,
     * мы проверяем local storage, и если в нем есть нужные нам поля, и они не пустые,
     * то мы перезаписываем занчения в local state
     */
    useEffect(() => {
        if (localStorage.getItem('scale')) {
            setStageScale(+localStorage.getItem('scale'));
        }
        if (localStorage.getItem('stageX') && localStorage.getItem('stageY')) {
            setStageCoordinates({ stageX: +localStorage.getItem('stageX'), stageY: +localStorage.getItem('stageY') });
        }
    }, []);

    /**
     * обработчик прокрутки нашего конваса, при событии "wheel" мы
     * изменяем scale нашего конваса, записываем новое значение в стейт
     * и в local storage
     * @param {object} e объект события
     */
    const stageWheelHandler = (e) => {
        e.evt.preventDefault();
        const scaleBy = 1.02;
        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const mousePointTo = getNewCoordinatesForMouse(stage, oldScale);
        const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
        setStageScale(newScale);
        localStorage.setItem('scale', newScale);
        setStageCoordinates({
            stageX: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
            stageY: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
        });
    };

    /**
     * обработчик, срабатывает после того как мы перестали перетаскивать наш канвас.
     * получает новые координаты канваса, устанавливает их в local state и устанавливает соответсвующие
     * значения в local storage
     * @param {object} e объект события
     */
    const stageDragEndHandler = (e) => {
        e.evt.preventDefault();
        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const mousePointTo = getNewCoordinatesForMouse(stage, oldScale);
        const emptySpace = e.target === e.target.getStage();
        if (emptySpace) {
            setStageCoordinates({
                stageX: -(mousePointTo.x - stage.getPointerPosition().x / oldScale) * oldScale,
                stageY: -(mousePointTo.y - stage.getPointerPosition().y / oldScale) * oldScale,
            });
        }
        localStorage.setItem('stageX', -(mousePointTo.x - stage.getPointerPosition().x / oldScale) * oldScale);
        localStorage.setItem('stageY', -(mousePointTo.y - stage.getPointerPosition().y / oldScale) * oldScale);
    };

    /**
     * обработчик двойного клика на канвас
     * при двойном клике выезжает плашка для создания схемы
     * @param {object} e объект события
     */
    const convasDoubleHandler = (e) => {
        const stage = e.target.getStage();
        const emptySpace = e.target === stage;

        if (emptySpace && !state.versionInfo?.stable) {
            const coordinates = getNewCoordinatesForMouse(stage, stage.scaleX());
            dispatch({ type: ActionsTypes.SET_STAGE_CURSOR_POSITION, payload: coordinates });
            dispatch({ type: ActionsTypes.SET_SCHEME_EDITOR, payload: true });
        }
    };

    /**
     * обработчик клика на канвас
     * при клике закрывается окно модификации или создания схемы
     * @param {object} e объект события
     */
    const convasClickHandler = (e) => {
        const emptySpace = e.target === e.target.getStage();
        if (emptySpace) {
            dispatch({ type: ActionsTypes.SET_SCHEME_EDITOR, payload: false });
        }
    };

    const centeringHandler = () => {
        if (state.convasDiagramItems.length > 0) {
            const coordinates = stageCentering(
                state.convasDiagramItems,
                stageScale,
                stageContainerRef.current?.clientWidth,
                stageContainerRef.current?.clientHeight,
            );
            setStageCoordinates({ stageX: coordinates.x, stageY: coordinates.y });
            localStorage.setItem('stageX', coordinates.x);
            localStorage.setItem('stageY', coordinates.y);
        } else {
            setStageCoordinates({ stageX: 0, stageY: 0 });
        }
    };

    //массив наших схем
    const convasItemsArray = state.convasDiagramItems.map((item) => (
        <ConvasSingleItem key={item.id} id={item.id} node={item} />
    ));

    //массив стрелочек
    const convasArrowsArray = [];
    state.convasDiagramItems.forEach((element) => {
        element.includedSchemesId.forEach((item) => {
            const secondNode = state.convasDiagramItems.find((node) => node.id === item);
            convasArrowsArray.push(
                <ConvasArrows key={item - Math.random()} secondNode={secondNode} firstNode={element} />,
            );
        });
    });

    return (
        <SchemeAreaConteiner>
            {state.versionInfo && <SchemeGroupsCard />}
            {state.selectedSource && <ConsumersCard />}

            {state.isVesrionSelected ? (
                <StageWrapper ref={stageContainerRef}>
                    <CenteringButton onClick={centeringHandler} variant='outlined'>
                        {t('Center all')}
                    </CenteringButton>
                    <Stage
                        onWheel={stageWheelHandler}
                        width={stageContainerRef.current?.clientWidth}
                        height={stageContainerRef.current?.clientHeight}
                        scaleX={stageScale}
                        scaleY={stageScale}
                        onClick={convasClickHandler}
                        onDblClick={convasDoubleHandler}
                        draggable
                        onDragEnd={stageDragEndHandler}
                        x={stageCoordinates.stageX}
                        y={stageCoordinates.stageY}
                    >
                        <Context.Provider value={{ state, dispatch }}>
                            <Layer>{convasArrowsArray}</Layer>
                            <Layer>{convasItemsArray}</Layer>
                        </Context.Provider>
                    </Stage>
                </StageWrapper>
            ) : null}
        </SchemeAreaConteiner>
    );
};
export default SchemeArea;
