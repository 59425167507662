import { useContext, useEffect, useState } from 'react';
import Context from '../../../store/context';
import updateGroupMeta from './updateGroupMeta';

/**
 * Кастомнгый хук длдя получения цвета
 * @param {number} colorIndex индекс цвета в масиве цветов. если -1, то параметр не передан, занчит гурпп нет
 * @param {object} meta в нем есть поле group_color, которое содержит информацию о цвете
 * @param {number} groupId id группы
 * @returns цвет группы
 */
const useGroupColor = (options) => {
    const { meta, colorIndex, groupId } = options;
    const { state, token, dispatch } = useContext(Context);
    const [color, setColor] = useState('fff');

    useEffect(() => {
        const asyncFunction = async () => {
            const versionId = state.versionInfo.id;
            if (groupId) {
                if (meta && meta.group_color?.scheme) {
                    setColor(meta.group_color.scheme);
                } else {
                    if (state.groupsForSource.length >= state.colorsArray.length) {
                        await updateGroupMeta({
                            colorsArray: state.colorsArray,
                            index: state.groupsForSource.length % 10,
                            dispatch,
                            groupId,
                            baseUrl: state.urls.BASE_URL,
                            token,
                            versionId: versionId,
                        });
                    } else {
                        await updateGroupMeta({
                            colorsArray: state.colorsArray,
                            index: state.groupsForSource.length,
                            dispatch,
                            groupId,
                            baseUrl: state.urls.BASE_URL,
                            token,
                            versionId: versionId,
                        });
                    }
                }
            }
        };

        asyncFunction();
    }, [colorIndex, state.colorsArray, meta, groupId]);

    return color;
};

export default useGroupColor;
