import useFetchData from '../utils/useFetchData';
import Header from './header/header';
import { useStyles } from './main-styles';
import SchemeArea from './schemeArea/schemeArea';
import SchemeEditorMenu from './schemeEditorMenu/schemeEditorMenu';

/**
 * обертка для хедера и области со схемами
 */
const MainInterface = () => {
    const classes = useStyles();
    useFetchData();
    return (
        <div className={classes.app__wrapper}>
            <Header />
            <SchemeArea />
            <SchemeEditorMenu />
        </div>
    );
};

export default MainInterface;
