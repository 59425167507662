import { useTranslation } from 'react-i18next';
import Accordions from './components/accordions';
import { SchemeSelectModalWrapper, SchemeSelectTitle, AccordionsWrapper } from './schemeSelectModal-styles';

/**
 * Модальное окно доя отображения аккардионов из групп и схем, принадлежащих конкретной группе
 * @param {array} groups массив всех групп
 * @param {array} validSchemesArray массив подходящих схем для выбора
 * @param {function} selectHandler функция для обработки нажатия на схему
 */
const SchemeSelectModal = ({ groups, validSchemesArray, selectHandler }) => {
    const { t } = useTranslation();

    const accordions = groups.map((element) => (
        <Accordions
            key={element.id}
            schemes={validSchemesArray}
            groupId={element.id}
            groupColor={element.frontend_meta.group_color}
            groupName={element.name}
            selectHandler={selectHandler}
        />
    ));

    return (
        <SchemeSelectModalWrapper>
            <SchemeSelectTitle>{t('Choice of dependency')}</SchemeSelectTitle>
            <AccordionsWrapper>{accordions}</AccordionsWrapper>
        </SchemeSelectModalWrapper>
    );
};

export default SchemeSelectModal;
