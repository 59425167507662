import { Button, Modal, TextField } from '@material-ui/core';
import styled from 'styled-components/macro';

export const Edit = styled.img`
    width: 15px;
    cursor: pointer;
    margin-right: 5px;
`;
export const EditModalWrapper = styled.div`
    margin-right: 5px;
    height: 15px;
`;

export const CustomModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const EditTitle = styled.div`
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
`;

export const PreviousName = styled.div`
    margin-top: 5px;
    span {
        font-weight: 700;
    }
    font-size: 14px;
`;

export const CustomTextField = styled(TextField)`
    font-size: 15px;
    margin-top: 5px;
    width: 100%;
`;

export const TextFieldWrapper = styled.div`
    flex-grow: 1;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 300px;
    min-width: 20%;
    outline: 0;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;
`;

export const CustomButton = styled(Button)`
    text-transform: none;
    font-size: 14px;
    border: ${(props) => `1px solid ${props.newcolor}`};
    color: ${(props) => props.newcolor};
`;
