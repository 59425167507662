import { Button, Modal } from '@material-ui/core';
import styled from 'styled-components/macro';

export const Trash = styled.img`
    width: ${(props) => props.width};
    margin-left: 5px;
    cursor: pointer;
`;

export const CustomModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModalContainer = styled.div`
    outline: 0;
    width: 25%;
`;

export const ConfirmModalWrapper = styled.div`
    width: 30%;
    border-radius: 5px;
    min-height: 90px;
    padding: 20px 5px 5px 20px;
    background-color: #ffffff;
`;

export const ConfirmTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
`;

export const ConfirmText = styled.div`
    font-size: 14px;
    margin-top: 20px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const CustomButton = styled(Button)`
    margin-left: 10px;
`;

export const DeleteButton = styled(Button)`
    text-transform: none;
    font-size: 14px;
    color: ${(props) => props.newcolor};
    border: ${(props) => (props.disabled ? `1px solid rgba(0, 0, 0, 0.12)` : `1px solid ${props.newcolor}`)};
`;
