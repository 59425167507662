import styled from 'styled-components/macro';

/**
 * стили для компонента header.jsx
 */

export const TitleWrapper = styled.div`
    display: flex;
    padding: 5px;
    box-shadow: 0 3px 2px -2px gray;
`;

export const WrappedItems = styled.div`
    display: flex;
    align-items: center;
`;
export const Lang = styled.div`
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
`;

export const Container = styled.div`
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
    min-height: ${(props) => props.height + 'px'};
    width: 100%;
    background-color: rgb(2, 42, 57);
    padding: 5px 20px 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
