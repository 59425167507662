/**
 * Функция для проверки примеров на наличие положительного и отрицательного примеров
 * @param {array} examples массив примеров для выбранной схемы
 */
const processExamplesForSchemeLink = (examples) => {
    let successFlag = false;
    let failureFlag = false;

    examples.forEach((example) => {
        if (example.valid && example.positive) successFlag = true;
        if (example.valid && !example.positive) failureFlag = true;
    });

    return successFlag && failureFlag;
};

export default processExamplesForSchemeLink;
