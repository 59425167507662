import { Box, Button } from '@material-ui/core';
import styled from 'styled-components/macro';

/**
 * стили для компонента codeEditor.js
 */

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const EditorWrapper = styled.div`
    margin-top: 20px;
`;

export const TitleButtonsWrapper = styled.div`
    margin-right: 10px;
    :last-child {
        margin-right: 0px;
    }
`;

export const CustomButton = styled(Button)`
    text-transform: none;
    font-size: 14px;
    color: ${(props) => (props.newcolor ? props.newcolor : null)};
    border: ${(props) => (props.newcolor ? `1px solid ${props.newcolor}` : null)};
`;

export const KeyBindsText = styled.div`
    text-transform: uppercase;
    font-size: 16px;
    text-align: right;
    flex: 0 0 45%;
`;

export const KeyBindsWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const MonacoWrapper = styled(Box)`
    margin-top: 20px;
    resize: vertical;
    width: 100%;
    height: 200px;
    padding-bottom: 15px;
    position: relative;
    overflow: hidden;
`;

export const ShowFullButton = styled(Button)`
    text-transform: none;
    font-size: 14px;
`;

export const FullScreenIcon = styled.img`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
