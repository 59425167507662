import { monaco } from 'react-monaco-editor';
import checkLineForBrackets from './checkLineForBrackets';

/**
 * функция делает HotKeys для редактора
 * @param {object} editor экземпляр редактора
 * @param {array} schemes массив схем из стейта
 */
const codeEditorHotKeys = (editor, schemes) => {
    editor.addAction({
        // Уникальный идентификатор внесенного действия.
        id: 'unique-scheme-insert',

        // label, который будет представлен пользователю.
        label: 'scheme-insert',

        // Необязательный массив сочетаний клавиш для действия
        keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_D],

        // Предварительное условие для этого действия.
        precondition: null,

        // Праивло поверх предварительного условия.
        keybindingContext: null,

        contextMenuGroupId: 'navigation',

        contextMenuOrder: 1.5,

        /**
         * hotkey Ctrl+d, при нажатии на который
         * добавляется в код редактора надпись ("__schema__": id первой схемы из массива)
         * @param {object} editor экземпляр редактора.
         */
        run: function (editor) {
            const selection = editor.getSelection();
            const lineValue = editor.getModel().getLineContent(selection.startLineNumber);

            //если функция вернула true, то вставка разрешена
            if (checkLineForBrackets(lineValue, selection.startColumn - 1)) {
                const id = { major: 1, minor: 1 };
                let text = '';
                let spaceCount = 0;
                if (schemes.length > 0) {
                    text = `"__schema__": ${schemes[0].id}`;
                    spaceCount = Math.floor((text.length + 2) * 1.8) - text.length;

                    if (lineValue.includes(',')) {
                        text += ' '.repeat(spaceCount - 1) + ',';
                    }
                }
                const op = { identifier: id, range: selection, text, forceMoveMarkers: true };
                editor.executeEdits('my-source', [op]);
            }

            return null;
        },
    });
};

export default codeEditorHotKeys;
