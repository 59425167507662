/**
 * Вспомогательная функция, которая отслеживает измменялся ли пример и диспатчит измененый пример в стейт
 * @param {boolean} flag изменялся пример или нет
 * @param {string} type тип для диспатча
 * @param {function} dispatch диспатч
 * @param {string} code код примера
 * @param {boolean} switchValue значение свитча в примере
 * @param {number} id id прмиера
 * @param {function} setEqual функция для устанвоки примера: изменялся или нет
 */
const exampleHelper = (options) => {
    const { flag, type, dispatch, code, switchValue, id, setEqual } = options;

    const example = {
        exampleCode: code,
        positive: switchValue,
        id,
    };
    setEqual(flag);
    dispatch({
        type,
        payload: { example, add: !flag },
    });
};

export default exampleHelper;
