import { Tooltip, Typography } from '@material-ui/core';
import { useContext, useEffect, useState, memo } from 'react';
import Context from '../../../store/context';
import createArrayofValidShemes from '../../../utils/createArrayOfValidSchemes';
import insertNewValue from '../../../utils/monacoUtils/insertNewValue';
import ModalContainer from '../../modals/modalContainer';
import SchemeSelectModal from '../../modals/schemeSelectModal/schemeSelectModal';
import { CodeEditorButton, CustomFormControl } from './codeEditorSelect-styles';

/**
 *  компонент для отрисовки селекта в редакторе
 * @param {number} startLine линия редактора, где находитя __schema__
 * @param {number} startColumn начальная колонка редактора, где находится __schema__
 * @param {number} endColumn конечная колонка редактора, где находится __schema__
 * @param {number} scrollTop расстояние от верхней границы редактора
 * @param {array} editorValues массив объектов, где в каждом объекте нохидится поле content: __schema__: id, ranges:
 * там информация о местонахожении __schema__: id в редакторе
 * @param {object} editorRef ссылка на савм редактор, необхъодимо для получения экземпляра редактора
 * @param {number} scrollLeft расстояние от левой границы редактора
 * @param {string} schemeId id схемы
 */
const CodeEditorSelect = ({
    startLine,
    startColumn,
    endColumn,
    scrollTop,
    editorValues,
    editorRef,
    scrollLeft,
    schemeId,
}) => {
    const [selectValue, setSelectValue] = useState('');
    const { state } = useContext(Context);
    const [validSchemesArray, setValidSchemesArray] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setValidSchemesArray(
            createArrayofValidShemes(
                state.schemes.filter((item) => item.id !== +schemeId),
                [+schemeId],
            ),
        );
    }, [state.schemes, schemeId]);

    /**
     * слдеит за изменение массива значений. Здесь мы пробегаемся по массиву значений и если нашли совпадение
     * с переданной строкой startLineNumber, то мы вытаскиваем от туда значение id путем сплита, если там нет id
     * то вставолеям id первой схемы из массива схем
     */
    useEffect(() => {
        const reg = /\S*?(?=})/gm;
        if (editorValues.length > 0 && state.schemes.length > 0 && validSchemesArray.length > 0) {
            editorValues.forEach((element) => {
                if (element.content && element.ranges.startLineNumber === startLine) {
                    const contentArr = element.content.split(':');
                    if (contentArr[1].match(reg)) {
                        const scheme = validSchemesArray.find((element) => element.id === +contentArr[1].match(reg)[0]);

                        if (scheme) {
                            setSelectValue(`${scheme.name}, ${scheme.id}`);
                        }

                        if (
                            validSchemesArray.length ===
                            validSchemesArray.filter((item) => item.id !== +contentArr[1].match(reg)[0]).length
                        ) {
                            insertNewValue({
                                editorRef,
                                startLine,
                                startColumn,
                                endColumn,
                                schemeId: validSchemesArray[0].id,
                            });
                            setSelectValue(`${validSchemesArray[0].name}, ${validSchemesArray[0].id}`);
                        }
                    } else {
                        insertNewValue({
                            editorRef,
                            startLine,
                            startColumn,
                            endColumn,
                            schemeId: validSchemesArray[0].id,
                        });
                        setSelectValue(`${validSchemesArray[0].name}, ${validSchemesArray[0].id}`);
                    }
                }
            });
        }
    }, [editorValues, startLine, state.schemes, schemeId, validSchemesArray]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //значение строки где найдено совпадение с __schema__
    const lineValue = editorRef.current.editor.getModel().getLineContent(startLine);
    //ключ для {"__schema__": id} без пробелов
    const textSizeBeforeSchemeNoSpaces = lineValue
        .trim()
        .slice(0, lineValue.trim().indexOf('{"__schema__": '))
        .trim().length;

    //текст до {"__schema__": id} вместе с пробелами
    const texSizetBeforeSchemeWithSpaces = lineValue.slice(0, lineValue.indexOf('{"__schema__": ')).length;

    //высчитываем расстояние от левого края с учетом всех пробелов и ключа
    const distanceToTheLeft =
        (texSizetBeforeSchemeWithSpaces - textSizeBeforeSchemeNoSpaces + textSizeBeforeSchemeNoSpaces) * 8.43 + 68;

    // const selectHandler = (e) =>  setSelectValue(e.target.value);

    /**
     * при клике на айтем селекта вставляем в редактор значение из этого селекта
     * @param {number} schemeId id схемы
     */
    // const itemHandler = (schemeId) => () => insertNewValue({ editorRef, startLine, startColumn, endColumn, schemeId });

    const selectHandler = (schemeId, schemeName) => {
        setSelectValue(`${schemeName}, ${schemeId}`);
        insertNewValue({ editorRef, startLine, startColumn, endColumn, schemeId });
    };

    return (
        <>
            <ModalContainer open={open} handleClose={handleClose}>
                <SchemeSelectModal
                    groups={state.groupsForSource}
                    validSchemesArray={validSchemesArray}
                    selectHandler={selectHandler}
                />
            </ModalContainer>
            <Tooltip title={<Typography fontSize={14}>{selectValue}</Typography>}>
                <CustomFormControl
                    left={distanceToTheLeft}
                    scrollleft={scrollLeft}
                    startline={startLine}
                    startcolumn={startColumn}
                    endcolumn={endColumn}
                    scrolltop={scrollTop}
                    selectedSchemeId={selectValue.split(', ')[1]?.length || 1}
                >
                    <CodeEditorButton variant='outlined' onClick={handleOpen}>
                        {selectValue}
                    </CodeEditorButton>
                </CustomFormControl>
            </Tooltip>
        </>
    );
};
export default CodeEditorSelect;
