/**
 * функция для получения кординта относительно мыши
 * @param {object} stage экземпляр конваса
 * @param {number} oldScale прежний скейл
 * @returns
 */
const getNewCoordinatesForMouse = (stage, oldScale) => {
    return {
        x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };
};

export default getNewCoordinatesForMouse;
