import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { patchRequest } from '../../../api/api';
import Context from '../../../store/context';
import { ActionsTypes } from '../../../store/reducer';
import {
	ContentWrapper,
	EditTitle,
	PreviousName,
	TextFieldWrapper,
	CustomButton,
	CustomTextField,
} from './editModal-styles';

const GroupEditModal = ({ groupId, previousGroupName, handleClose }) => {
	const { state, dispatch, token } = useContext(Context);
	const [editInputValue, setEditInputValue] = useState('');
	const [error, setError] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const editInputHandler = (e) => {
		setError(false);
		setEditInputValue(e.target.value);
	};

	const editGroupName = async () => {
		if (editInputValue) {
			const requestBody = {
				name: editInputValue,
			};

			//ищем индекс выбранной группы
			const index = state.groupsForSource.findIndex((item) => item.id === groupId);
			//делаем копию массива групп
			const groupsCopy = state.groupsForSource.slice();
			//ожидание ответа от сервера
			const data = await patchRequest(`${state.urls.BASE_URL}groups/${groupId}/`, requestBody, token);
			/**
			 * если ответ пришел без ошибки, то показываем диалогове окно с успехом,
			 * и диспатчим новый массив групп с измененной выбранной группой
			 * и закрываем модальное окно
			 */
			if (!data.isError) {
				enqueueSnackbar('Group name changed', {
					variant: 'success',
				});
				groupsCopy[index] = data;
				dispatch({ type: ActionsTypes.GET_GROUPS_FOR_SOURCE, payload: groupsCopy });
				//иначе показываем ошибку и закрываем модальное окно
			} else {
				const errorKey = Object.keys(data.error.data)[0];
				if (errorKey) {
					enqueueSnackbar(data.error.data[errorKey], {
						variant: 'error',
					});
				} else {
					enqueueSnackbar('Server error', {
						variant: 'error',
					});
				}
			}
			setEditInputValue('');
			handleClose();
		} else {
			setError(true);
		}
	};
	return (
		<ContentWrapper>
			<EditTitle>{t('Group edit')}</EditTitle>
			<PreviousName>
				<span>{`${t('Previous group name')}: ${previousGroupName}`}</span>
			</PreviousName>
			<TextFieldWrapper>
				<CustomTextField
					value={editInputValue}
					onChange={editInputHandler}
					label={t('New group name')}
					helperText={error ? 'This field is required' : null}
					error={error ? true : false}
				/>
			</TextFieldWrapper>
			<CustomButton onClick={editGroupName} newcolor={'green'} variant="outlined">
				{t('Save')}
			</CustomButton>
		</ContentWrapper>
	);
};

export default GroupEditModal;
