/**
 * функция возвращает массива объектов, в каждом объекте содержатся поля:
 * startLineNumber - строка где найдено слово __scheme__,
 * startColumn - колонка, с которой начинается __scheme__,
 * endLineNumber - строка, где слова __scheme__ заканчиватеся,
 * endColumn - колонка, где кончается __scheme__
 * @param {object} editor экземпляр редактора
 */
const getRangesFromEditor = (editor) => {
	return editor
		.getModel()
		.findMatches('{"__schema__": ')
		.map((item) => {
			return item.range;
		});
};

export default getRangesFromEditor;
