import { Box } from '@material-ui/core';
import styled from 'styled-components/macro';

export const FullScreenSchemeEditorModalWrapper = styled.div`
    outline: 0;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    width: 60%;
    border-radius: 5px;
    height: 95%;
    padding: 10px;
    display: flex;
    flex-direction: column;
`;

export const FullSCreenEditorTitle = styled.div`
    text-align: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
`;

export const FullScreenEditorMonacoWrapper = styled(Box)`
    width: 100%;
    height: 85%;
    overflow: hidden;
    margin-top: 10px;
    flex-grow: 1;
    position: relative;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
