import { useContext, useEffect, useReducer, useState } from 'react';
import MainInterface from './components/main';
import Context from './store/context';
import reducer, { ActionsTypes } from './store/reducer';
import { getRequest } from './api/api';
import { useTranslation } from 'react-i18next';
import { CssBaseline } from '@material-ui/core';
import useWindowDimensions from './utils/getwindowDimensions';
import { GlobalStyle } from './app-styles';
import { SnackbarProvider } from 'notistack';
import ModalForAliasCopy from './components/modals/aliasCopy/modalForAliasCopy';
import parseQuery from './utils/parseQuery';
import deleteParamsFromString from './utils/deleteParamsFromString';
import ModalContainer from './components/modals/modalContainer';

/**
 * обертка приложения, провайдет стейта и диспатча, обнуление стилей(CssBaseLine)
 */
const App = () => {
    const initialState = useContext(Context);
    const [token, setToken] = useState('');
    const { i18n } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { height, width } = useWindowDimensions();

    /**
     * авторизация, если токена нет, то берем его из local storage, а если и там нет, то
     * перекидываем на страницу авторизации, после входа убираем появившейся токен из строки
     * поиска
     */
    useEffect(() => {
        const query = parseQuery(window.location.search);
        const myToken = query.token ? query.token : localStorage.getItem('xtoken');

        if (!myToken || myToken === 'undefined') {
            window.location.replace(`${state.urls.AUTH_URL}?next=` + document.location.href, '_blank');
        }
        setToken(myToken);
        if (token) {
            localStorage.setItem('xtoken', token);
        }
        window.history.pushState(null, null, deleteParamsFromString(window.location.href, ['token']));
    }, [token, state.urls.AUTH_URL]);

    /**
     * гет запрос для источников
     */
    useEffect(() => {
        if (token) {
            getRequest(
                dispatch,
                `${state?.urls?.BASE_URL}sources/`,
                {
                    actionName: ActionsTypes.GET_SCHEME_SOURCES,
                },
                token,
            );
        }
    }, [state.urls.BASE_URL, token]);

    /**
     * диспатч размеров окна в стейт
     */
    useEffect(() => {
        dispatch({ type: ActionsTypes.GET_WINDOW_DIMENSIONS, payload: { height, width } });
    }, [height, width]);

    /**
     * изменение языка при нажатии на кнопку языка
     */
    useEffect(() => {
        i18n.changeLanguage(state.lang);
    }, [state.lang, i18n]);

    const handleClose = () => {
        dispatch({ type: ActionsTypes.SET_INFO_FOR_ALIAS_COPY, payload: {} });
    };

    return (
        <Context.Provider value={{ state, dispatch, token }}>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <CssBaseline />
                <GlobalStyle open={state.schemeEditor} />
                <MainInterface />
                <ModalContainer open={state.infoForAliasCopy.isOpen || false} handleClose={handleClose}>
                    <ModalForAliasCopy />
                </ModalContainer>
            </SnackbarProvider>
        </Context.Provider>
    );
};

export default App;
