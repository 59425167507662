import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Context from '../../../store/context';
import { ActionsTypes } from '../../../store/reducer';
import { postRequest } from '../../../api/api';
import { FormWrapper, FormTitle, SourceInfo, InputWrapper, CustomTextField, CustomButton } from './createModal-styles';
/**
 * модалка для создания нового потребителя
 */
const CreateNewConsumer = () => {
	const { state, dispatch, token } = useContext(Context);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [error, setError] = useState(false);
	const [inputValue, setInputValue] = useState('');

	const inputHandler = (e) => {
		setError(false);
		setInputValue(e.target.value);
	};

	const createConsumerHandler = async () => {
		if (inputValue) {
			const requestBody = {
				source_id: state.selectedSource.id,
				token: inputValue,
			};
			//ожидание ответа от сервера
			const data = await postRequest(`${state.urls.BASE_URL}consumers/`, requestBody, token);
			/**
			 * если ответ пришел без ошибки, то показываем диалоговое окно с успехом, диспатчим новый
			 * массив консьюмеров, включая нового, и закрываем модальное окно
			 */
			if (!data.isError) {
				enqueueSnackbar('Consumer added', {
					variant: 'success',
				});

				dispatch({
					type: ActionsTypes.GET_CONSUMERS_FOR_SOURCE,
					payload: [...state.consumersForSource, data],
				});

				//иначе показываем ошибку и закрываем модальное окно
			} else {
				const errorKey = Object.keys(data.error.data)[0];
				if (errorKey) {
					enqueueSnackbar(data.error.data[errorKey], {
						variant: 'error',
					});
				} else {
					enqueueSnackbar('Server error', {
						variant: 'error',
					});
				}
			}
			setInputValue('');
		} else {
			setError(true);
		}
	};

	return (
		<FormWrapper>
			<FormTitle>{t('Create new consumer')}</FormTitle>
			<SourceInfo>
				<span>{t('Source alias')}:</span> {state.selectedSource.alias}
			</SourceInfo>
			<InputWrapper>
				<CustomTextField
					error={error ? true : false}
					value={inputValue}
					onChange={inputHandler}
					label={t('Group name')}
					helperText={error ? 'This field is required.' : null}
				/>
			</InputWrapper>

			<CustomButton onClick={createConsumerHandler} variant="outlined">
				{t('Create')}
			</CustomButton>
		</FormWrapper>
	);
};

export default CreateNewConsumer;
