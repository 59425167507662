import { Button } from '@material-ui/core';
import styled from 'styled-components/macro';

/**
 * стили для компонента ExamplesForEditor
 */

export const ExamplesWrapper = styled.div`
    margin-top: 20px;
`;

export const ExamplesTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
`;

export const NoExamples = styled.div`
    font-size: 14px;
    font-style: italic;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const CustomButton = styled(Button)`
    text-transform: none;
    font-size: 14px;
`;
