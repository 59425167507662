import styled from 'styled-components/macro';

/**
 * стили для компонента SingleConsumer
 */
export const SingleConsumerText = styled.div`
    transition: 0.5s all;
    opacity: ${(props) => (props.arrow ? '1' : '0')};
    line-height: ${(props) => (props.arrow ? '14px' : '0px')};
    font-size: ${(props) => (props.arrow ? '14px' : '0px')}; ;
`;
export const SingleConsumerWrapper = styled.div`
    transition: 0.5s all;
    margin-top: ${(props) => (props.arrow ? '10px' : '0')};
    height: ${(props) => (props.arrow ? '20px' : '0px')};
    display: flex;
    opacity: ${(props) => (props.arrow ? '1' : '0')};
    align-items: center;
    justify-content: space-between;
`;

export const ModalsWrapper = styled.div`
    display: flex;
    align-items: center;
`;
