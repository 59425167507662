import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Context from '../../../store/context';
import ModalContainer from '../../modals/modalContainer';
import {
    GroupsCardTitle,
    Arrow,
    GroupsCardWrapper,
    ItemsWrapper,
    GroupCardCreateButton,
    CreateWrapper,
} from './schemeGroupsCard-styles';
import SchemeGroupsCardItem from './schemeGroupsCardItem';
import CreateNewGroup from '../../modals/createModal/createNewGroup';

/**
 * обертка для отображения отдельной группы
 */
const SchemeGroupsCard = () => {
    const [arrow, setArrow] = useState(true);
    const { state } = useContext(Context);
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <ModalContainer open={open} handleClose={handleClose}>
                <CreateNewGroup />
            </ModalContainer>
            <GroupsCardWrapper arrow={arrow.toString()} boxShadow={3}>
                <Arrow onClick={() => setArrow(!arrow)}>{arrow ? '▼' : '▲'}</Arrow>
                <GroupsCardTitle>{t('Scheme Groups')}</GroupsCardTitle>
                <ItemsWrapper>
                    {state.groupsForSource.length > 0 ? (
                        state.groupsForSource.map((item) => (
                            <SchemeGroupsCardItem
                                arrow={arrow}
                                groupId={item.id}
                                meta={item.frontend_meta}
                                key={item.id}
                                name={item.name}
                                colorIndex={item.index}
                            />
                        ))
                    ) : (
                        <SchemeGroupsCardItem arrow={arrow} />
                    )}
                </ItemsWrapper>
                <CreateWrapper arrow={arrow.toString()}>
                    <GroupCardCreateButton arrow={arrow.toString()} onClick={handleOpen} variant='outlined'>
                        {t('Create')}
                    </GroupCardCreateButton>
                </CreateWrapper>
            </GroupsCardWrapper>
        </>
    );
};
export default SchemeGroupsCard;
