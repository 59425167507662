import { useContext, useEffect, useRef, useState } from 'react';
import Context from '../../store/context';
import {
    Arrow,
    EditorTitle,
    InputsWrapper,
    CustomFormControl,
    EditorWrapper,
    SelectText,
    CustomTextField,
    CustomSelect,
} from './schemeEditorMenu-styles';
import arrow from '../../assets/icons/right-arrow.svg';
import { ActionsTypes } from '../../store/reducer';
import { FormHelperText, InputLabel, MenuItem } from '@material-ui/core';
import CodeEditor from './components/codeEditor';
import ExamplesForEditor from './components/examplesForEditor';
import { useTranslation } from 'react-i18next';

/**
 * компонент для отрисовки бокового выезжающего меню
 */
const SchemeEditorMenu = () => {
    const { state, dispatch } = useContext(Context);
    const [schemeName, setSchemeName] = useState('');
    const [schemeNameValidateError, setSchemeValidateError] = useState(false);
    const [schemeAlias, setSchemeAlias] = useState('');
    const [groupSelectData, setGroupSelectData] = useState('');
    const { t } = useTranslation();
    const [groupSelectDataValidateError, setGroupSelectDataValidateError] = useState(false);
    const aliasRef = useRef();

    useEffect(() => {
        setSchemeValidateError(state.schemeNameValidateError);
    }, [state.schemeNameValidateError]);

    useEffect(() => {
        setGroupSelectDataValidateError(state.schemeGroupValidateError);
    }, [state.schemeGroupValidateError]);

    /**
     * срабатывает при изменение выбранной схемы или изменения
     * массива групп для выбранного источника
     * если у выбранной схемы нет имени, то показываем меню содания новой схемы
     */
    useEffect(() => {
        dispatch({ type: ActionsTypes.SET_SCHEME_NAME_VALIDATE_ERROR, payload: false });
        dispatch({ type: ActionsTypes.SET_SCHEME_GROUP_VALIDATE_ERROR, payload: false });
        if (state.selectedScheme.schemeName !== undefined) {
            setSchemeName(state.selectedScheme.schemeName);
            if (state.selectedScheme.schemeAlias === null) {
                setSchemeAlias('');
            } else {
                setSchemeAlias(state.selectedScheme.schemeAlias);
            }
            setGroupSelectData(state.selectedScheme.schemeGroupId);
        } else {
            setSchemeName('');
            setSchemeAlias('');
            setGroupSelectData('');
        }
        // eslint-disable-next-line
    }, [state.selectedScheme, state.groupsForSource, state.convasDiagramItems]);

    //обработчик нажатия на стрелочку закрытия выезжающей плажки
    const arrowHandler = () => {
        dispatch({ type: ActionsTypes.SET_SCHEME_EDITOR, payload: false });
    };

    const slectGroupsHandler = (e) => {
        setGroupSelectData(e.target.value);
        dispatch({ type: ActionsTypes.SET_SCHEME_GROUP_VALIDATE_ERROR, payload: false });
    };

    const errorNameHandler = (e) => {
        setSchemeName(e.target.value);
        dispatch({ type: ActionsTypes.SET_SCHEME_NAME_VALIDATE_ERROR, payload: false });
    };
    return (
        <EditorWrapper open={state.schemeEditor} boxShadow={3}>
            <Arrow onClick={arrowHandler} src={arrow} alt='arrow rigth' />
            {state.selectedScheme.schemeName ? (
                state.versionInfo?.stable ? (
                    <EditorTitle>{t('Schema info')}</EditorTitle>
                ) : (
                    <EditorTitle>{t('Schema modification')}</EditorTitle>
                )
            ) : (
                <EditorTitle>{t('Create schema')}</EditorTitle>
            )}
            <InputsWrapper>
                <CustomFormControl>
                    {schemeNameValidateError ? (
                        <CustomTextField
                            error
                            label='Name'
                            value={schemeName}
                            onChange={errorNameHandler}
                            helperText='This field is required'
                        />
                    ) : (
                        <CustomTextField
                            onChange={(e) => setSchemeName(e.target.value)}
                            id='schemeName'
                            label='Name'
                            InputProps={
                                state.versionInfo?.stable
                                    ? {
                                          readOnly: true,
                                      }
                                    : null
                            }
                            value={schemeName}
                        />
                    )}
                </CustomFormControl>

                <CustomFormControl error={groupSelectDataValidateError ? true : false}>
                    <InputLabel htmlFor='groupSelect'>Groups</InputLabel>
                    <CustomSelect
                        id='groupSelect'
                        value={groupSelectData}
                        onChange={slectGroupsHandler}
                        inputProps={
                            state.versionInfo?.stable
                                ? {
                                      readOnly: true,
                                  }
                                : null
                        }
                    >
                        {state.groupsForSource.map((element) => (
                            <MenuItem value={element.id} key={element.id}>
                                <SelectText>{element.name}</SelectText>
                            </MenuItem>
                        ))}
                    </CustomSelect>

                    {groupSelectDataValidateError && <FormHelperText>This is required</FormHelperText>}
                </CustomFormControl>

                <CustomFormControl>
                    <CustomTextField
                        ref={aliasRef}
                        onChange={(e) => setSchemeAlias(e.target.value)}
                        id='schemeAlias'
                        label='Alias'
                        InputProps={
                            state.versionInfo?.stable
                                ? {
                                      readOnly: true,
                                  }
                                : null
                        }
                        value={schemeAlias}
                    />
                </CustomFormControl>
            </InputsWrapper>
            <CodeEditor
                schemeId={state.selectedScheme.schemeId}
                groupSelectData={groupSelectData}
                schemeName={schemeName}
                schemeAlias={schemeAlias}
                setSchemeName={setSchemeName}
                setSchemeAlias={setSchemeAlias}
                setGroupSelectData={setGroupSelectData}
                examples={state.selectedScheme?.examples}
            />
            <ExamplesForEditor
                schemeGroupId={state.selectedScheme?.schemeGroupId}
                schemeId={state.selectedScheme?.schemeId}
                examples={state.selectedScheme?.examples}
            />
        </EditorWrapper>
    );
};
export default SchemeEditorMenu;
