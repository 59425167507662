import Alert from '@material-ui/lab/Alert';
import { useSnackbar } from 'notistack';
import { useContext, useRef, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Context from '../../../store/context';
import { ActionsTypes } from '../../../store/reducer';
import {
    AliasCopyTitle,
    CustomListItem,
    ListWrapper,
    CustomList,
    ContentWrapper,
    TextAreaForCopy,
    ConfirmCopyWrapper,
    InformationContainer,
    StyledLink,
    StyledListItemText,
} from './modalForAliasCopy-styles';

/**
 * функция для отображения модального окна, в котором пользователь может
 * выбрать потребитя и получить ссылку в зависимости от источника, версии, описания схемы и токена
 * потребителя
 */
const ModalForAliasCopy = () => {
    const { state, dispatch } = useContext(Context);
    const [checkedValue, setCheckedValue] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const copyRef = useRef();

    useEffect(() => {
        if (checkedValue) {
            const token = state.consumersForSource.filter((item) => item.id === checkedValue)[0].token;
            copyLink(`${mainUri}.json?token=${token}`);
        }
    }, [checkedValue]);

    const mainUri = useMemo(() => {
        if (
            state.urls.URL_FOR_COPY &&
            state.selectedSource?.alias &&
            state.versionInfo?.version &&
            state.infoForAliasCopy?.schemeAlias
        ) {
            return `${state.urls.URL_FOR_COPY}/${state.selectedSource?.alias}/v${state.versionInfo?.version}/${state.infoForAliasCopy?.schemeAlias}`;
        }

        return '';
    }, [state.selectedSource, state.urls.URL_FOR_COPY, state.versionInfo.version, state.infoForAliasCopy?.schemeAlias]);

    const copyLink = (link) => () => {
        copyRef.current.value = link;
        copyRef.current.focus();
        copyRef.current.select();
        try {
            document.execCommand('copy');
            enqueueSnackbar('Link coppied', {
                variant: 'success',
            });
            dispatch({ type: ActionsTypes.SET_INFO_FOR_ALIAS_COPY, payload: {} });
        } catch (error) {
            enqueueSnackbar('Coppy Error', {
                variant: 'error',
            });
        }
    };

    const onLinkClick = (href) => () => {
        window.open(href, '_blank');
        dispatch({ type: ActionsTypes.SET_INFO_FOR_ALIAS_COPY, payload: {} });
    };

    //нажатие на потребителя устанавливает значение в local state
    const consumerClickHandler = (id) => () => {
        const token = state.consumersForSource.filter((item) => item.id === id)[0].token;
        copyLink(`${mainUri}.json?token=${token}`)();
        setCheckedValue(id);
    };

    //массив jsx элементов, где кажый элемент - это отдельный потребитель
    const consumersArray = state.consumersForSource.map((item) => (
        <CustomListItem
            key={item.token}
            role='listitem'
            button
            disabled={!state.infoForAliasCopy.copyability}
            onClick={consumerClickHandler(item.id)}
        >
            <StyledListItemText primary={item.token} />
        </CustomListItem>
    ));

    if (!state.infoForAliasCopy.isOpen) return <div></div>;

    return (
        <ContentWrapper>
            <TextAreaForCopy ref={copyRef} />
            <AliasCopyTitle>{t('Create link for copy')}</AliasCopyTitle>
            <InformationContainer>
                <span>{t('Scheme alias')}: </span>
                {state.infoForAliasCopy?.schemeAlias}
            </InformationContainer>
            <InformationContainer>
                <span>{t('Source')}: </span>
                {state.selectedSource?.alias}
            </InformationContainer>
            <InformationContainer>
                <span>{t('Version number')}: </span>
                {state.versionInfo?.version}
            </InformationContainer>
            <InformationContainer>
                <span>{t('Docs')}: </span>
                <StyledLink component='button' onClick={onLinkClick(`${mainUri}.docs`)}>
                    {t('go_to_link')}
                </StyledLink>
                ,
                <StyledLink component='button' onClick={copyLink(`${mainUri}.docs`)}>
                    {t('copy_link')}
                </StyledLink>
            </InformationContainer>
            <InformationContainer>
                <span>{t('Examples')}: </span>
                <StyledLink component='button' onClick={onLinkClick(`${mainUri}.examples`)}>
                    {t('go_to_link')}
                </StyledLink>
                ,
                <StyledLink component='button' onClick={copyLink(`${mainUri}.examples`)}>
                    {t('copy_link')}
                </StyledLink>
            </InformationContainer>
            <div>{t('Select one of the consumers')}:</div>
            {state.consumersForSource.length > 0 ? (
                <ListWrapper boxShadow={1}>
                    <CustomList dense component='div' role='list'>
                        {consumersArray}
                    </CustomList>
                </ListWrapper>
            ) : (
                <div>{t('No consumers')}</div>
            )}
            {!state.infoForAliasCopy.copyability && (
                <ConfirmCopyWrapper>
                    <Alert severity='error'>
                        {t('To copy the link, you need to have (at least) 1 successful example and 1 unsuccessful.')}
                    </Alert>
                </ConfirmCopyWrapper>
            )}
        </ContentWrapper>
    );
};
export default ModalForAliasCopy;
