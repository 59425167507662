import { Button } from '@material-ui/core';
import styled from 'styled-components/macro';

/**
 * стили для компонента ButtonsForEditor
 */
export const CustomButton = styled(Button)`
    font-size: 14px;
    text-transform: none;
    color: ${(props) => props.newcolor};
    border: ${(props) => '1px solid' + props.newcolor};
    margin-right: 10px;
    width: 100px;
    :last-child {
        margin-right: 0;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: ${(props) => props.marginTop + 'px'};
`;
