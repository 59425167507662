import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Context from '../../../store/context';
import { deleteRequest } from '../../../api/api';
import { ConfirmModalWrapper, ConfirmTitle, ConfirmText, ButtonsWrapper, CustomButton } from './confirmModal-styles';
import { ActionsTypes } from '../../../store/reducer';
/**
 * модалка для удаления примера
 * @param {function} handleClose функция для закрытия модалки
 * @param {number} exampleId id примера
 * @returns
 */
const ExamplesDeleteConfirm = ({ handleClose, exampleId }) => {
	const { state, dispatch, token } = useContext(Context);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const deleteExampleHandler = async () => {
		//ожидание ответа от сервера
		const data = await deleteRequest(`${state.urls.BASE_URL}examples/${exampleId}/`, token);
		/**
		 * если ответ без ошибки, то показываем диалоговое окно с успехом, удаляем выбранный пример
		 * из массива всех примеров, так же из массива примеров выбранной схемы
		 */
		if (!data.isError) {
			enqueueSnackbar('Success delete', {
				variant: 'success',
			});
			dispatch({
				type: ActionsTypes.GET_EXAMPLES_FOR_VERSION,
				payload: state.examplesForVersion.filter((item) => item.id !== exampleId),
			});
			dispatch({
				type: ActionsTypes.UPDATE_EXAMPLES_FOR_SELECTED_SCHEME,
				payload: state.selectedScheme.examples.filter((item) => item.id !== exampleId),
			});

			// иначе показываем ошибку и закрываем модальное окно
		} else {
			const errorKey = Object.keys(data.error.data)[0];
			if (errorKey) {
				enqueueSnackbar(data.error.data[errorKey], {
					variant: 'error',
				});
			} else {
				enqueueSnackbar('Server error', {
					variant: 'error',
				});
			}
		}
	};

	return (
		<ConfirmModalWrapper>
			<ConfirmTitle>{t('Are you sure?')}</ConfirmTitle>
			<ConfirmText>{t('This will delete an example')}</ConfirmText>
			<ButtonsWrapper>
				<CustomButton onClick={handleClose}>{t('Cancel')}</CustomButton>
				<CustomButton color="primary" onClick={deleteExampleHandler}>
					Ok
				</CustomButton>
			</ButtonsWrapper>
		</ConfirmModalWrapper>
	);
};

export default ExamplesDeleteConfirm;
