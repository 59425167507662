import { FormControl, Box, Input, TextField, Select } from '@material-ui/core';
import styled from 'styled-components/macro';
/**
 * стили для компонента SchemeEditorMenu
 */

export const Arrow = styled.img`
    position: absolute;
    top: 20px;
    left: 20px;
    width: 20px;
`;

export const CustomInput = styled(Input)`
    font-size: 14px;
`;

export const CustomTextField = styled(TextField)`
    font-size: 14px;
`;

export const SelectText = styled.div`
    font-size: 14px;
`;

export const EditorTitle = styled.div`
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
`;

export const InputsWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
`;

export const CustomFormControl = styled(FormControl)`
    flex: 0 0 30%;
`;

export const EditorWrapper = styled(Box)`
    transition: 0.5s all;
    position: absolute;
    padding: 45px 20px 20px 20px;
    top: 0;
    right: ${(props) => (props.open ? '0' : '-41%')};
    height: 100vh;
    width: 40%;
    background-color: rgb(255, 255, 254);
    z-index: 52;
    overflow: auto;
`;

export const CustomSelect = styled(Select)``;
