import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteRequest } from '../../../api/api';
import Context from '../../../store/context';
import { ActionsTypes } from '../../../store/reducer';
import { ConfirmText, ButtonsWrapper, CustomButton, ConfirmModalWrapper, ConfirmTitle } from './confirmModal-styles';
/**
 * модалка для удаления схемы
 * @param {function} handleClose функция для закрытия модалки
 * @param {string} schemeId id схемы
 * @returns
 */
const SchemeDeleteConfirm = ({ handleClose, schemeId }) => {
	const { state, dispatch, token } = useContext(Context);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const schemeDeleteHandler = async () => {
		let deleteFlag = true;
		state.schemes.forEach((element) => {
			if (element.included_schemas.includes(+schemeId)) {
				deleteFlag = false;
			}
		});
		if (!deleteFlag) {
			enqueueSnackbar('Remove all dependencies', {
				variant: 'error',
			});
		} else {
			//ожидание ответа от сервера
			const data = await deleteRequest(`${state.urls.BASE_URL}schemas/${+schemeId}/`, token);
			/**
			 * если ответ без ошибки, то показываем диалоговое окно с успехом, удаляем выбранную схему
			 * из массива всех схем, и закрываем модальное окно
			 */
			if (!data.isError) {
				enqueueSnackbar('Success delete', {
					variant: 'success',
				});
				dispatch({
					type: ActionsTypes.GET_SCHEMES_ARRAY,
					payload: state.schemes.filter((item) => item.id !== +schemeId),
				});
				dispatch({ type: ActionsTypes.SET_SCHEME_EDITOR, payload: false });
				handleClose();
				// иначе показываем ошибку и закрываем модальное окно
			} else {
				const errorKey = Object.keys(data.error.data)[0];
				if (errorKey[0] !== '0') {
					enqueueSnackbar(data.error.data[errorKey], {
						variant: 'error',
					});
				} else {
					enqueueSnackbar('Server error', {
						variant: 'error',
					});
				}
			}
		}
	};

	return (
		<ConfirmModalWrapper>
			<ConfirmTitle>{t('Are you sure?')}</ConfirmTitle>
			<ConfirmText>{t('This will delete this scheme')}</ConfirmText>
			<ButtonsWrapper>
				<CustomButton onClick={handleClose}>{t('Cancel')}</CustomButton>
				<CustomButton color="primary" onClick={schemeDeleteHandler}>
					Ok
				</CustomButton>
			</ButtonsWrapper>
		</ConfirmModalWrapper>
	);
};

export default SchemeDeleteConfirm;
