import { Box, Button, Checkbox, List, ListItem, ListItemIcon, Modal, Link, ListItemText } from '@material-ui/core';
import styled from 'styled-components/macro';

/**
 * стили для клмпонента ModalForaAliasCopy
 */

export const CustomModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    outline: 0;
    min-width: 20%;
    position: relative;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px;
    max-height: 100%;
    min-height: 250px;
`;

export const AliasCopyTitle = styled.div`
    text-align: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
`;

export const InformationContainer = styled.div`
    font-size: 14px;
    span {
        font-weight: 700;
    }
`;

export const CustomList = styled(List)`
    padding: 0;
`;

export const CustomListItem = styled(ListItem)`
    padding: 0;
`;

export const CustomCheckBox = styled(Checkbox)`
    padding: 0;
`;

export const CustomListItemIcon = styled(ListItemIcon)`
    min-width: 30px;
`;

export const ListWrapper = styled(Box)`
    overflow: auto;
    width: 100%;
    flex-grow: 1;
`;

export const TextAreaForCopy = styled.textarea`
    position: absolute;
    top: -10000000%;
    left: -10000000%;
`;

export const ConfirmCopyWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

export const StyledLink = styled(Link)`
    margin-left: 10px;
    :first-child {
        margin-left: 0px;
    }
`;

export const StyledListItemText = styled(ListItemText)`
    padding: 5px;
`;
