import { monaco } from 'react-monaco-editor';

/**
 * функция для вставки нового значения в редактор
 * @param {object} editorRef ссылка на редактор
 * @param {number} startLine линия вставки
 * @param {number} startColumn колокнка с которой вставить
 * @param {number} endColumn колонка где закончить вставку
 * @param {number} schemeId id схемы которую нужно вставить
 */
const insertNewValue = (options) => {
    const { editorRef, startLine, startColumn, endColumn, schemeId } = options;
    const currentLineContent = editorRef.current.editor.getModel().getLineContent(startLine);
    //вызываем изменение редактора: очищаем строку начиная с колонки которую передали и до конца
    editorRef.current.editor.executeEdits('clear-line', [
        { range: new monaco.Range(startLine, startColumn, startLine, endColumn + 1000), text: null },
    ]);

    const spaceCount = Math.ceil((endColumn - startColumn + String(schemeId).length + 1) * 0.8);

    /**
     * вызываем изменение редактора: вставляем выбранное значение из селекта на место,
     * которое передали в параметрах компоненита
     */
    editorRef.current.editor.executeEdits('new-line', [
        {
            range: new monaco.Range(startLine, startColumn, startLine, endColumn + 1000),
            text: `{"__schema__": ${schemeId}}${currentLineContent.includes(',') ? `${' '.repeat(spaceCount)},` : ''}`,
        },
    ]);
};

export default insertNewValue;
