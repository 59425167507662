import { Box, Button } from '@material-ui/core';
import styled from 'styled-components/macro';

/**
 * стили для компонента schemeGroupsCard.jsx
 */

export const GroupsCardTitle = styled.div`
    text-align: center;
    font-size: 16px;
`;

export const Arrow = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
`;

export const GroupsCardWrapper = styled(Box)`
    transition: 0.5s all;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 10px;
    left: 20px;
    width: 200px;
    min-height: ${(props) => (props.arrow === 'true' ? '200px' : '0px')};
    background-color: rgba(255, 255, 255, 0.3);
    padding: 10px 10px 10px 10px;
    z-index: 53;
`;

export const ItemsWrapper = styled.div`
    flex-grow: 1;
`;

export const GroupCardCreateButton = styled(Button)`
    margin-top: ${(props) => (props.arrow === 'true' ? '10px' : '0px')};
    font-size: ${(props) => (props.arrow === 'true' ? '14px' : '0px')};
    opacity: ${(props) => (props.arrow === 'true' ? '1' : '0')};
    padding: ${(props) => (props.arrow === 'true' ? '5px 15px' : '0')};
    height: ${(props) => (props.arrow === 'true' ? 'auto' : '0')};
    text-transform: none;
`;

export const CreateWrapper = styled.div`
    height: ${(props) => (props.arrow === 'true' ? 'auto' : '0px')};
`;
