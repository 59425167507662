import { MenuItem } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { getRequest } from '../../api/api';
import Context from '../../store/context';
import { ActionsTypes } from '../../store/reducer';
import { useTranslation } from 'react-i18next';
import {
    CustomSelect,
    HeaderSelectWrapper,
    VersionMenuItem,
    Version,
    SourceMenuItem,
    CustomInputLabel,
} from './headerSelect-styles';

/**
 *
 * компонент для отображения селектов
 * @param {string} label лэйбл для селекта
 * @param {string} initialData начальное значение по умолчанию пустая строка
 * @param {array} arrayOfMenuItems массив возможных элементов селекта
 * @param {boolean} showVersions флаг, отвечающий за то показать версии или нет
 */
const HeaderSelect = ({ label, arrayOfMenuItems, showVersions = false }) => {
    const {
        state: { selectedSource, versionInfo },
        dispatch,
        token,
    } = useContext(Context);
    const { t } = useTranslation();

    /**
     * обработчик нажатия на поле селекта с источниками. Гет запрос для получения версий для источника.
     * Гет запрос для получения групп для источника
     * @param {number} id id источника
     * @param {string} alias описание источнкиа
     */
    const sourcesMenuItemHandler = (id, alias) => () => {
        dispatch({
            type: ActionsTypes.SET_SELECTED_SOURCE,
            payload: {
                id,
                alias,
            },
        });
    };

    /**
     * Обрабротчик нажатия на поле селекта с версиями. Диспатч тайтла. гет запрос для получения схем
     * для конкретной версии, закрывает schemeEditor, если он открыт
     * @param {number} version номер версии
     * @param {boolean} stable стабильная/нестабильная версия
     * @param {number} versionId id версии
     */
    const versionMenuItemhandler = (version, stable, versionId) => async () => {
        dispatch({ type: ActionsTypes.SET_VERSION_INFO, payload: { id: versionId, version: version, stable: stable } });
    };

    return (
        <HeaderSelectWrapper>
            <CustomInputLabel id='demo-controlled-open-select-label'>{t(`${label}`)}</CustomInputLabel>
            <CustomSelect
                labelId='demo-controlled-open-select-label'
                value={showVersions ? versionInfo?.id || '' : selectedSource?.id || ''}
            >
                {arrayOfMenuItems.length > 0 ? (
                    arrayOfMenuItems.map((item) => (
                        <MenuItem
                            key={item.id}
                            onClick={
                                showVersions
                                    ? versionMenuItemhandler(item.version, item.stable, item.id)
                                    : sourcesMenuItemHandler(item.id, item.alias)
                            }
                            value={item.id}
                            fontSize={14}
                        >
                            {showVersions ? (
                                <VersionMenuItem>
                                    <Version>V{item.version}</Version>
                                    <div>{item.stable ? t('stable') : t('unstable')}</div>
                                </VersionMenuItem>
                            ) : (
                                <SourceMenuItem>{item.alias}</SourceMenuItem>
                            )}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem value=''>No schemes</MenuItem>
                )}
            </CustomSelect>
        </HeaderSelectWrapper>
    );
};

export default HeaderSelect;
