import React, { useContext, useEffect, useState } from 'react';
import { ActionsTypes } from '../../../store/reducer';
import { Circle, Group, Rect, Text } from 'react-konva';
import Context from '../../../store/context';
import { patchRequest } from '../../../api/api';
import processExamplesForSchemeLink from '../../../utils/processExamplesForSchemeLink';

/**
 *  функция генериурет jsx разметку каждой отдельной схемы
 * @param {object} node  объект схемы с куячей полуй, поля можно посмотреть setConvasDiagramItems.js
 */
const ConvasSingleItem = ({ node }) => {
    const { state, dispatch, token } = useContext(Context);
    const [titleText, setTitleText] = useState(0);
    const [groupText, setGroupText] = useState(0);
    const [examplesText, setExamplesText] = useState(0);
    const [aliasText, setAliasText] = useState(0);
    const [groupRef, setGroupRef] = useState(null);
    const [rectangleHeight, setRectangleHeight] = useState(0);
    const [validExamplesNumber, setValidExamplesNumber] = useState(0);

    /**
     * этот useEffect следит за тем выбранна ли данная конкретная схема, если она выбрана,
     * то делает ее непрозрачной, инчае opacity: 0.5
     */
    useEffect(() => {
        if (groupRef && +node.id === +state.selectedScheme.schemeId) {
            groupRef.to({
                opacity: 1,
                duration: 0.2,
            });
        } else {
            if (groupRef) {
                groupRef.to({
                    opacity: 0.5,
                    duration: 0.2,
                });
            }
        }
    }, [state.selectedScheme, groupRef, node.id, state.convasDiagramItems]);

    /**
     * следит за изменением ссылок на название схемы, название группы, примеров, и описания,
     * если ссылка поменялсяь, то с помощью метода height мы получаем высоту конкретного блока,
     * и записываем в сумму всех высот этих блоков в переменную rectangleHeight
     */
    useEffect(() => {
        if (
            titleText &&
            groupText &&
            examplesText &&
            aliasText &&
            aliasText.height() + titleText.height() + groupText.height() + examplesText.height() > node.height
        ) {
            setRectangleHeight(
                aliasText.height() + titleText.height() + groupText.height() + examplesText.height() - 10,
            );
        }
    }, [titleText, groupText, examplesText, aliasText, node.height]);

    /**
     * слдеит за изменением rectangleHeight, если переменная поменялась, то диспатчит
     * высоту этой ноды в стейт
     */
    useEffect(() => {
        if (rectangleHeight > node.height) {
            dispatch({ type: ActionsTypes.CHANGE_NODE_HEIGHT, payload: { id: node.id, height: rectangleHeight } });
        }
    }, [rectangleHeight, node.id, state.nodeParams.height, dispatch, node.height]);

    /**
     * следит за изменением примеров для схемы, если они изменились, то проверется их валидность и правильность,
     * если оба параметрвы равны true , то увеличиваем счетчик правильных примеров на 1
     */
    useEffect(() => {
        setValidExamplesNumber(0);
        if (node.examples.length > 0) {
            node.examples.forEach((element) => {
                if (element.valid) {
                    setValidExamplesNumber((prevState) => (prevState += 1));
                }
            });
        }
    }, [node.examples]);

    /**
     * обработчик начала движения схемы устанавливает прозрачность в 0.9
     * и меняет расположение схемы в массиве схем, чтобы перетаскиваемая схема была всегда поверх остальных
     * и диспатчит новый масив в стейт
     * @param {object} e объект события
     */
    const convasItemDragStartHandler = (e) => {
        groupRef.to({
            opacity: 0.9,
            duration: 0.2,
        });
        //получение id схемы на которой происзодит событие
        const id = e.target.id();
        //делаем копию масива convasDiagramItems
        const items = state.convasDiagramItems.slice();
        //получаем элемент на котором произощло событие
        const item = items.find((element) => element.id === id);
        //получение индеска элимента на котором произошло событие из массива
        const index = items.indexOf(item);
        //удаление этого элемента из массива
        items.splice(index, 1);
        //добавляем элемент в начало массива
        items.push(item);

        //dispatch нового массива в стейт
        dispatch({ type: ActionsTypes.SET_CONVAS_DIAGRAM_ITEMS, payload: items });
    };

    /**
     *  при движении меняет координаты перетаскиваемой схемы
     * и диспатчит изменения в стейт
     * @param {object} e объект события
     */
    const convasItemDragMoveHandler = (e) => {
        //получение имени перетаскиваемого объекта, если имя "alis", то координаты уже нужны другие
        const name = e.target.name();
        //получение id схемы на которой происзодит событие
        const id = e.target.id();
        //делаем копию масива convasDiagramItems
        const items = state.convasDiagramItems.slice();
        //получаем элемент на котором произощло событие
        const item = items.find((element) => element.id === id);
        //получение индеска элимента на котором произошло событие из массива
        const index = items.indexOf(item);
        //обновляем позицию элемента на котором произошло событие
        if (name === 'alias') {
            items[index] = {
                ...item,
                x: e.target.x(),
                y: e.target.y() - (examplesText.height() + titleText.height() + groupText.height() - 25),
            };
        } else {
            items[index] = {
                ...item,
                x: e.target.x(),
                y: e.target.y(),
            };
        }

        //dispatch нового массива в стейт
        dispatch({ type: ActionsTypes.SET_CONVAS_DIAGRAM_ITEMS, payload: items });
    };

    /**
     * после окончания передвижения схемы, провереят является ли схема выбранной, если нет,
     * то устанавливает прозрачность в 0.5 и делает patch запрос на сервер для изменения
     * координат в мете
     * @param {object} e объект события
     */
    const convasItemDragEndHandler = (e) => {
        if (groupRef && node.id === state.selectedScheme.schemeId) {
            groupRef.to({
                opacity: 1,
                duration: 0.2,
            });
        } else {
            groupRef.to({
                opacity: 0.5,
                duration: 0.2,
            });
        }
        const x = e.target.x();
        const y = e.target.y();
        patchRequest(`${state.urls.BASE_URL}schemas/${+node.id}/`, { frontend_meta: { coordinates: { x, y } } }, token);
    };

    /**
     * обработчик нажатия на схему.
     * пушит выбранную схему в стейт.
     * ставит опасити в 1
     */
    const groupHandler = () => {
        groupRef.to({
            opacity: 1,
            duration: 0.2,
        });
        const selectedScheme = {
            schemeName: node.schemeName,
            schemeAlias: node.alias,
            schemeGroup: node.groupName,
            buildedScheme: JSON.stringify(node.scheme.schema_json, null, 4),
            shortScheme: JSON.stringify(node.scheme.schema, null, 4),
            examples: node.examples,
            schemeId: node.id,
            schemeGroupId: node.schemeGroupId,
        };
        dispatch({ type: ActionsTypes.SET_SELECTED_SCHEME, payload: selectedScheme });
        if (!state.schemeEditor) {
            dispatch({ type: ActionsTypes.SET_SCHEME_EDITOR, payload: true });
        }
    };

    //при клике на плашку с описание, открываем модальное окно
    const aliasRectangleHandler = () => {
        const copyability = processExamplesForSchemeLink(node.examples);
        dispatch({
            type: ActionsTypes.SET_INFO_FOR_ALIAS_COPY,
            payload: { isOpen: true, schemeAlias: node.alias, copyability },
        });
    };

    //при наведении на плашку с описанеие делаем cursor: pointer
    const aliasRectangleMouseEnter = (e) => {
        // style stage container:
        const container = e.target.getStage().container();
        container.style.cursor = 'pointer';
    };
    //как только мы убираем мышь с плашки с описанием, то курсор становится нормальным
    const aliasRectangleMouseLeave = (e) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'default';
    };

    return (
        <>
            <Rect
                x={node.x}
                y={node.y}
                width={node.width}
                height={rectangleHeight}
                fill={'white'}
                strokeWidth={4}
                dashEnabled={node.examples.length > 0 && node.examples.length === validExamplesNumber ? false : true}
                dash={[10, 4]}
                cornerRadius={20}
            />
            <Group ref={(node) => setGroupRef(node)}>
                <Rect
                    id={node.id}
                    x={node.x}
                    y={node.y}
                    width={node.width}
                    fill={
                        node.groupMeta && node.groupMeta?.group_color?.scheme
                            ? node.groupMeta.group_color.scheme
                            : state.colorsArray[node.groupColorId].scheme
                    }
                    height={rectangleHeight}
                    stroke={
                        node.groupMeta && node.groupMeta?.group_color?.border
                            ? node.groupMeta.group_color.border
                            : state.colorsArray[node.groupColorId].border
                    }
                    cornerRadius={20}
                    strokeWidth={4}
                    dashEnabled={
                        node.examples.length > 0 && node.examples.length === validExamplesNumber ? false : true
                    }
                    dash={[10, 4]}
                />

                <Text
                    x={node.x}
                    y={node.y}
                    text={node.schemeName}
                    fontSize={16}
                    align={'center'}
                    width={node.width}
                    ref={(node) => setTitleText(node)}
                    padding={10}
                />
                <Text
                    x={node.x}
                    y={titleText ? node.y + titleText.height() - 15 : node.y}
                    text={node.groupName}
                    fontSize={14}
                    align={'center'}
                    width={node.width}
                    ref={(node) => setGroupText(node)}
                    padding={10}
                />

                <Text
                    x={node.x}
                    y={titleText && groupText ? node.y + titleText.height() + groupText.height() - 25 : node.y}
                    text={
                        node.examples.length > 0
                            ? `${validExamplesNumber}/${node.examples.length} examples`
                            : 'no examples'
                    }
                    fontSize={14}
                    align={'center'}
                    width={node.width}
                    ref={(node) => setExamplesText(node)}
                    padding={10}
                />

                <Circle
                    x={node.x + 16}
                    y={titleText && groupText ? node.y + titleText.height() + groupText.height() - 8 : node.y}
                    width={15}
                    height={15}
                    fill={node.examples.length > 0 && node.examples.length === validExamplesNumber ? 'green' : 'red'}
                />

                <Rect
                    x={node.x + 10}
                    y={
                        titleText && groupText && examplesText
                            ? node.y + examplesText.height() + titleText.height() + groupText.height() - 25
                            : node.y
                    }
                    width={node.alias ? node.width - 20 : 0}
                    height={node.alias ? (aliasText ? aliasText.height() + 2 : 0) : 0}
                    fill={'white'}
                    stroke={'black'}
                    strokeWidth={1}
                    cornerRadius={10}
                />
                <Text
                    x={node.x + 7}
                    y={
                        titleText && groupText && examplesText
                            ? node.y + examplesText.height() + titleText.height() + groupText.height() - 24
                            : node.y
                    }
                    text={node.alias ? node.alias : ''}
                    width={node.width - 20}
                    fontSize={node.alias ? 14 : 0}
                    padding={node.alias ? 5 : 0}
                    ref={(node) => setAliasText(node)}
                    align={'center'}
                />

                <Rect
                    id={node.id}
                    x={node.x}
                    y={node.y}
                    width={node.width}
                    fill={'transparent'}
                    draggable
                    strokeWidth={4}
                    dashEnabled={
                        node.examples.length > 0 && node.examples.length === validExamplesNumber ? false : true
                    }
                    onClick={groupHandler}
                    dash={[10, 4]}
                    cornerRadius={20}
                    height={rectangleHeight}
                    onDragStart={convasItemDragStartHandler}
                    onDragEnd={convasItemDragEndHandler}
                    onDragMove={convasItemDragMoveHandler}
                />
                <Rect
                    id={node.id}
                    container='alias'
                    onClick={aliasRectangleHandler}
                    x={node.x + node.width / 2 - 50}
                    y={
                        titleText && groupText && examplesText
                            ? node.y + examplesText.height() + titleText.height() + groupText.height() - 25
                            : node.y
                    }
                    width={node.alias ? 100 : 0}
                    height={node.alias ? (aliasText ? aliasText.height() + 2 : 0) : 0}
                    fill={'transparent'}
                    name='alias'
                    cornerRadius={10}
                    draggable
                    onMouseEnter={aliasRectangleMouseEnter}
                    onMouseLeave={aliasRectangleMouseLeave}
                    onDragStart={convasItemDragStartHandler}
                    onDragEnd={convasItemDragEndHandler}
                    onDragMove={convasItemDragMoveHandler}
                />
            </Group>
        </>
    );
};
export default ConvasSingleItem;
