import { MenuItem } from '@material-ui/core';
import { useState } from 'react';
import { CustomAccordion, CustomAccordionDetails, CustomAccordionSummary, CustomTextField } from './accordions-styles';

/**
 * Компонент для отрисовки аккардиона для группы
 * @param {array} schemes массив всех схем
 * @param {number} groupId id группы
 * @param {object} groupColor объект с цветом схемы и цветом бордера
 * @param {string} groupName название группы
 * @param {function} selectHandler функция для обработки нажатия на схему
 */
const Accordions = ({ schemes, groupId, groupColor, groupName, selectHandler }) => {
    const [expanded, setExpanded] = useState('');
    const [filter, setFilter] = useState('');
    const [filteredItems, setFilteredItems] = useState(schemes);

    const itemHandler = (schemeId, schemeName) => () => {
        selectHandler(schemeId, schemeName);
    };

    const items = filteredItems
        .filter((element) => element.group_id === groupId)
        .map((element) => (
            <MenuItem
                key={element.id}
                onClick={itemHandler(element.id, element.name)}
            >{`${element.name}, id:${element.id}`}</MenuItem>
        ));

    const handleChange = (panel) => (_, newExpanded) => setExpanded(newExpanded ? panel : false);

    const filterHandler = (e) => {
        const value = e.target.value.toLowerCase();
        setFilter(value);
        if (value) {
            setFilteredItems((prevState) =>
                prevState.filter(
                    (element) => element.name.toLowerCase().includes(value) || element.id.toString().includes(value)
                )
            );
        } else {
            setFilteredItems(schemes);
        }
    };

    return (
        <CustomAccordion square expanded={expanded === `panel${groupId}`} onChange={handleChange(`panel${groupId}`)}>
            <CustomAccordionSummary color={groupColor.scheme} aria-controls='panel1d-content' id='panel1d-header'>
                <div>{groupName}</div>
            </CustomAccordionSummary>
            <CustomAccordionDetails>
                <CustomTextField value={filter} onChange={filterHandler} label='Filter' variant='outlined' />
                {items}
            </CustomAccordionDetails>
        </CustomAccordion>
    );
};

export default Accordions;
