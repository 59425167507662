import { Box, Button } from '@material-ui/core';
import styled from 'styled-components/macro';

/**
 * стили для компонента ConsumersCard
 */
export const ConsumersCardWrapper = styled(Box)`
	transition: 0.5s all;
	position: absolute;
	bottom: 10px;
	left: 20px;
	width: 200px;
	min-height: ${(props) => (props.arrow === 'true' ? '200px' : '0px')};
	z-index: 52;
	padding: 10px;
	display: flex;
	flex-direction: column;
`;

export const ConsumersTitle = styled.div`
	font-size: 16px;
	text-align: center;
`;

export const Arrow = styled.div`
	position: absolute;
	top: 10px;
	left: 10px;
	cursor: pointer;
`;

export const ConsumersWrapper = styled.div`
	transition: 0.5s all;
	flex-grow: 1;
	opacity: ${(props) => (props.arrow ? '1' : '0')};
`;

export const ConsumerCardCreateButton = styled(Button)`
	margin-top: ${(props) => (props.arrow === 'true' ? '10px' : '0px')};
	font-size: ${(props) => (props.arrow === 'true' ? '14px' : '0px')};
	opacity: ${(props) => (props.arrow === 'true' ? '1' : '0')};
	padding: ${(props) => (props.arrow === 'true' ? '5px 15px' : '0')};
	height: ${(props) => (props.arrow === 'true' ? 'auto' : '0')};
	text-transform: none;
`;

export const ConsumerCreateWrapper = styled.div`
	height: ${(props) => (props.arrow === 'true' ? 'auto' : '0px')};
`;
