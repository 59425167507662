/**
 * функция для проверки вставки между {}, вставка разрещена только между двумя скобками
 * @param {string} lineValue значение строки, на которой установлен курсор
 * @param {number} cursorPosition позиция курсора в строке
 */
const checkLineForBrackets = (lineValue, cursorPosition) => {
    //первый флаг - до позиции курсора не включая
    let firstFlag = false;

    //второй флаг после позиции курсора, включая курсор
    let secondFlag = false;

    //берем кусочек с начала счтроки и до курсора, не включая его
    const start = lineValue.slice(0, cursorPosition);

    //берем кусочек начиная с позиции курсора и до конца строки
    const end = lineValue.slice(cursorPosition, cursorPosition + 1);

    //убираем все пробелы и если значение получилось равным "}", то меняем значение флага в true
    if (end.trim() === '}') {
        secondFlag = true;
    }
    //если последний символ в строке спарва от курсора равен "{", то меняем значение флага в true
    if (start.trim().slice(-1) === '{') {
        firstFlag = true;
    }

    return secondFlag && firstFlag;
};

export default checkLineForBrackets;
