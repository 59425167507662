import styled from 'styled-components/macro';

export const SchemeSelectModalWrapper = styled.div`
    outline: 0;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    width: 40%;
    border-radius: 5px;
    max-height: 60%;
    padding: 10px;
    display: flex;
    flex-direction: column;
`;

export const SchemeSelectTitle = styled.div`
    text-align: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
`;

export const AccordionsWrapper = styled.div`
    max-height: 90%;
    overflow: auto;
    margin-top: 20px;
`;
