/**
 * функция для замены {
 *  "__schema": id
 * }
 * в одну строку {"__schema__": id}
 * @param {string} code json переделанный в строку
 */
const codeTextTransformer = (code) => {
    let codeString = code;
    // eslint-disable-next-line
    const findLinesRegExp = /{\n[ \t]*(\"__schema__\": \S*)\n[ \t]*}/;
    const findLinesRegWithCommaExp = /{\n[ \t]{1,}"__schema__": \d{1,}\n[ \t]{1,}}.?/;
    const searchRegExp = /"__schema__": \S*/gm;
    const arrayOfMatches = codeString.match(searchRegExp);
    if (arrayOfMatches && arrayOfMatches?.length > 0) {
        arrayOfMatches.forEach((item) => {
            const commaValue = codeString.match(findLinesRegWithCommaExp);
            if (commaValue && commaValue[0].includes(',')) {
                const spaceCount = Math.floor((item.length + 2) * 1.8) - item.length;
                codeString = codeString.replace(findLinesRegWithCommaExp, `{${item}}${' '.repeat(spaceCount - 1)},`);
            } else {
                codeString = codeString.replace(findLinesRegExp, `{${item}}`);
            }
        });
        return codeString;
    }

    return code;
};

export default codeTextTransformer;
