// export const urls = {
// 	BASE_URL: 'http://schema-service.cloud.avabr.xyz/api/',
// 	URL_FOR_COPY: 'http://schema-service.cloud.avabr.xyz',
// 	AUTH_URL: 'http://admin-service.cloud.avabr.xyz/sso/ajax-token',
// };

const host = { adminService: 'https://admin.focustech.xyz', mapService: 'https://schema-service.focustech.xyz' };

/**
 * Объект с изначальными адресами для ручек.
 */
export const urls = {
    AUTH_URL: `${host.adminService}/sso/ajax-token`,
    BASE_URL: `${host.mapService}/api/`,
    URL_FOR_COPY: `${host.mapService}`,
};
