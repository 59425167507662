/**
 * словарик для реализации перевода на странице
 */
const translations = {
    'Scheme Sources': 'Источники Схем',
    'Scheme Versions': 'Версии Схем',
    unstable: 'нестабильная',
    stable: 'стабильная',
    'Scheme Groups': 'Группы Схем',
    Consumers: 'Потребители',
    'No consumers': 'Нет потребителей',
    'Schema modification': 'Модификация схемы',
    'Create schema': 'Создание схемы',
    'Add consumer': 'Добавить потребителя',
    'Add group': 'Добавить группу',
    'Create new consumer': 'Создать нового потребителя',
    'Create new group': 'Создать новую группу',
    Create: 'Создать',
    'Source alias': 'Описание источника',
    'Consumer token': 'Токен потребителя',
    'Group name': 'Название группы',
    'Add example': 'Добавить пример',
    'Show full scheme': 'Показать собранную схему',
    'Built Scheme': 'Собранная схема',
    Save: 'Сохранить',
    'Copy link': 'Скопировать ссылку',
    Cancel: 'Отменить',
    Delete: 'Удалить',
    'Are you sure?': 'Вы уверены?',
    'This will delete this scheme': 'Это действие удалит схему',
    'This will delete an example': 'Это действие удалит пример',
    'This will delete this group': 'Это действие удалит группу',
    'This will delete this consumer': 'Это действие удалит потребителя',
    Examples: 'Примеры',
    'No examples': 'Нет примеров',
    'Create link for copy': 'Создание ссылки для копирования',
    'Scheme alias': 'Описание схемы',
    Source: 'Источник',
    'Version number': 'Номер версии',
    'Select one of the consumers': 'Выберите одного из потребителей',
    'Group edit': 'Изменение группы',
    'Consumer eddit': 'Изменение потребителя',
    'Previous group name': 'Предыдущее название группы',
    'Previous consumer token': 'Предыдущий токен потребителя',
    'New group name': 'Новое название группы',
    'New consumer token': 'Новый токен потребителя',
    'Schema info': 'Информация о схеме',
    'Center all': 'Отцентровка канваса',
    'Scheme editor': 'Редактор схемы',
    'Choice of dependency': 'Выбор зависимости',
    'Add existing schema': 'Добавить схему',
    'To copy the link, you need to have (at least) 1 successful example and 1 unsuccessful.':
        'Для копирования ссылки Вам необходимо иметь (как минимум) 1 успешный пример и 1 неуспешный.',
    'Docs page': 'Документация',
};

export default translations;
