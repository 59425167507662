import { useTranslation } from 'react-i18next';
import MonacoEditor from 'react-monaco-editor';
import { ModalWrapper, BuildedSchemeTitle, BuildedSchemeMonacoWrapper } from './buildedSchemeModal-styles';
/**
 *  компонент для отрисвоки модалки для показа собранной схемы
 * @param {string} buildedScheme собранная схема в виде строки
 * @returns
 */
const ShowFullSchemeModal = ({ buildedScheme }) => {
    const { t } = useTranslation();

    const options = {
        selectOnLineNumbers: true,
        automaticLayout: true,
        lineHeight: 19,
        fontSize: 14,
        fontFamily: 'Monaco, monospace',
        readOnly: true,
    };
    return (
        <ModalWrapper>
            <BuildedSchemeTitle>{t('Built Scheme')}</BuildedSchemeTitle>
            <BuildedSchemeMonacoWrapper boxShadow={2}>
                <MonacoEditor language='json' theme='vs' value={buildedScheme} options={options} />
            </BuildedSchemeMonacoWrapper>
        </ModalWrapper>
    );
};

export default ShowFullSchemeModal;
