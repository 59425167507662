import { makeStyles } from '@material-ui/core';
/**
 * стили для компонента main.jsx
 */

export const useStyles = makeStyles({
    app__wrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
        position: 'relative',
    },
});
