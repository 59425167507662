import { useContext, useEffect, useState } from 'react';
import Context from '../../store/context';
import { ActionsTypes } from '../../store/reducer';
import HeaderSelect from './headerSelect';
import { Lang, WrappedItems, Container } from './header-styles';
import setConvasDiagramItems from '../../utils/convasDiagram/setConvasDiagramItems';
// import setDiagram from '../../utils/setDiagram';
// import SingleNode from '../schemeArea/components/singleNode';

/**
 * обертка для селектов, тайтла, изменения языка
 */
const Header = () => {
    const [showVersions, setShowVersions] = useState(false);
    const { state, dispatch } = useContext(Context);

    useEffect(() => {
        if (state.selectedSource) {
            setShowVersions(true);
        } else {
            setShowVersions(false);
        }
    }, [state.selectedSource]);

    //диспатч нового языка
    const langHandler = () => {
        dispatch({ type: ActionsTypes.SET_NEW_LANG });
    };

    //получение массива диаграмм при изменение массива схем
    useEffect(() => {
        dispatch({ type: ActionsTypes.SET_CONVAS_DIAGRAM_ITEMS, payload: [] });
        if (state.schemes.length > 0) {
            // переменную вернется массив объектов для создания диаграммы
            const itemsForConvasDiagram = setConvasDiagramItems(
                state.schemes,
                state.windowDimensions.height,
                state.headerParams.height,
                state.nodeParams,
                state.examplesForVersion,
                state.groupsForSource,
            );
            dispatch({ type: ActionsTypes.SET_CONVAS_DIAGRAM_ITEMS, payload: itemsForConvasDiagram });
        }
        // eslint-disable-next-line
    }, [state.schemes, state.examplesForVersion, state.groupsForSource]);

    return (
        <Container>
            <WrappedItems>
                <HeaderSelect label='Scheme Sources' arrayOfMenuItems={state.schemaSources} />
                {showVersions && (
                    <HeaderSelect
                        label='Scheme Versions'
                        showVersions={showVersions}
                        arrayOfMenuItems={state.schemeVersions}
                    />
                )}
            </WrappedItems>
            <Lang onClick={langHandler}>{state.lang}</Lang>
        </Container>
    );
};

export default Header;
