/**
 *
 * Функция сохраняет все несохраненные примеры, так же делает Patch схемы.
 */
const schemePatchHelper = async (options) => {
    const {
        dispatch,
        groupSelectData,
        schemeAlias,
        schemeName,
        jsonCode,
        includedSchemesId,
        examplesForSave,
        patchRequest,
        token,
        enqueueSnackbar,
        BASE_URL,
        ActionsTypes,
        schemeId,
        getRequest,
        versionInfo,
        closeFullScreenEditor,
    } = options;

    const requestBody = {
        group_id: groupSelectData,
        alias: schemeAlias ? schemeAlias : null,
        name: schemeName,
        schema: jsonCode,
        included_schemas: includedSchemesId,
    };
    if (examplesForSave) {
        examplesForSave.forEach(async (element) => {
            try {
                const exampleBody = {
                    example: JSON.parse(element.exampleCode),
                    positive: element.positive,
                };
                await patchRequest(`${BASE_URL}examples/${element.id}/`, exampleBody, token);
            } catch (error) {
                enqueueSnackbar("Wrong example JSON. Can't update example", {
                    variant: 'error',
                });
            }
        });
        dispatch({ type: ActionsTypes.SET_EXAMPLES_FOR_SAVE, payload: { reset: true } });
    }

    const data = await patchRequest(`${BASE_URL}schemas/${schemeId}/`, requestBody, token);
    /**
     * ждем ответ от сервера, если ответ без ошибки, то обновляем массив всех схем
     * и закрываем окно редактора
     */
    if (!data.isError) {
        closeFullScreenEditor && closeFullScreenEditor();
        enqueueSnackbar('Scheme updated', {
            variant: 'success',
        });
        getRequest(
            dispatch,
            `${BASE_URL}schemas/?version_id=${versionInfo.id}`,
            {
                actionName: ActionsTypes.GET_SCHEMES_ARRAY,
            },
            token,
        );

        const examplesData = await getRequest(
            dispatch,
            `${BASE_URL}examples/?version_id=${versionInfo.id}`,
            {
                actionName: ActionsTypes.GET_EXAMPLES_FOR_VERSION,
            },
            token,
        );

        const newSelectedScheme = {
            buildedScheme: JSON.stringify(data.schema_json, null, 4),
            examples: examplesData.filter((element) => element.schema_id === +schemeId),
            schemeAlias: data.alias,
            schemeGroup: '',
            schemeGroupId: data.group_id,
            schemeId: data.id,
            schemeName: data.name,
            shortScheme: JSON.stringify(data.schema, null, 4),
        };

        dispatch({ type: ActionsTypes.SET_SELECTED_SCHEME, payload: newSelectedScheme });
        dispatch({ type: ActionsTypes.SET_MONACO_CODE_EQUAL_TO_STATE_CODE, payload: true });
    } else {
        let errorKey = '';
        if (data.error) {
            errorKey = Object.keys(data.error?.data)[0];
        }

        if (errorKey) {
            enqueueSnackbar(data.error.data[errorKey], {
                variant: 'error',
            });
        } else {
            enqueueSnackbar('Server error', {
                variant: 'error',
            });
        }
    }
};

export default schemePatchHelper;
