import { Modal, TextField, Button } from '@material-ui/core';
import styled from 'styled-components/macro';

export const CustomButton = styled(Button)`
    text-transform: none;
    font-size: 14px;
    padding: 5px 10px;
    margin-top: 10px;
`;

export const GroupModalWrapper = styled.div`
    margin-top: ${(props) => (props.arrow ? '10px' : '0px')};
    transition: 0.2s all;
    height: ${(props) => (props.arrow ? '40px' : '0px')};
    opacity: ${(props) => (props.arrow ? '1' : '0')};
`;

export const GroupModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalContainer = styled.div`
    outline: 0;
    min-width: 20%;
    min-height: 100px;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 5px;
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    outline: 0;
    min-width: 20%;
    min-height: 300px;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 5px;
`;

export const FormTitle = styled.div`
    text-align: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
`;

export const SourceInfo = styled.div`
    margin-top: 5px;
    font-size: 14px;
    span {
        font-weight: 600;
        font-size: 14px;
    }
`;

export const CustomTextField = styled(TextField)`
    width: 100%;
`;

export const InputWrapper = styled.div`
    margin-top: 5px;
    flex-grow: 1;
`;
