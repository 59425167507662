import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Context from '../../../store/context';
import CreateNewConsumer from '../../modals/createModal/createNewConsumer';
import ModalContainer from '../../modals/modalContainer';
import {
	ConsumersCardWrapper,
	ConsumersTitle,
	Arrow,
	ConsumersWrapper,
	ConsumerCreateWrapper,
	ConsumerCardCreateButton,
} from './consumersCard-styles';
import SingleConsumer from './singleConsumer';

const ConsumersCard = () => {
	const { state } = useContext(Context);
	const { t } = useTranslation();
	const [arrow, setArrow] = useState(true);
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	//создание массива jsx элементов, где каждый эоемент - это отдельный токен
	const consumersArray = state.consumersForSource.map((item) => (
		<SingleConsumer key={item.id - Math.random()} id={item.id} arrow={arrow} token={item.token} />
	));

	return (
		<>
			<ModalContainer open={open} handleClose={handleClose}>
				<CreateNewConsumer />
			</ModalContainer>
			<ConsumersCardWrapper arrow={arrow.toString()} boxShadow={3}>
				<Arrow onClick={() => setArrow(!arrow)}>{arrow ? '▼' : '▲'}</Arrow>
				<ConsumersTitle>{t('Consumers')}</ConsumersTitle>
				<ConsumersWrapper arrow={arrow}>
					{consumersArray.length > 0 ? (
						consumersArray
					) : (
						<SingleConsumer arrow={arrow} token={t('No consumers')} />
					)}
				</ConsumersWrapper>
				<ConsumerCreateWrapper arrow={arrow.toString()}>
					<ConsumerCardCreateButton arrow={arrow.toString()} onClick={handleOpen} variant="outlined">
						{t('Create')}
					</ConsumerCardCreateButton>
				</ConsumerCreateWrapper>
			</ConsumersCardWrapper>
		</>
	);
};

export default ConsumersCard;
