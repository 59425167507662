import { getRequest, patchRequest } from '../../../api/api';
import { ActionsTypes } from '../../../store/reducer';

/**
 * Функция для обновленя мета информации для группы
 * @param {array} colorsArray массив вскех цветов
 * @param {number} index номер цвета в массиве
 * @param {function} dispatch функция для диспатча
 * @param {number} groupId id группы
 * @param {string} baseUrl базолвый урл
 * @param {string} token токен для запросов
 * @param {number} versionId id версии
 * @returns null
 */
const updateGroupMeta = async (options) => {
    const { colorsArray, index, dispatch, groupId, baseUrl, token, versionId } = options;
    const response = await patchRequest(`${baseUrl}groups/${groupId}/`, {
        frontend_meta: {
            group_color: {
                scheme: colorsArray[index].scheme,
                border: colorsArray[index].border,
            },
        },
        token,
    });
    if (!response.error) {
        await getRequest(
            dispatch,
            `${baseUrl}groups/?version_id=${versionId}`,
            {
                actionName: ActionsTypes.GET_GROUPS_FOR_SOURCE,
            },
            token,
        );
    }

    return null;
};

export default updateGroupMeta;
