import axios from 'axios';

/**
 * функция для гет запросов
 * @param {function} dispatch
 * @param {string} url endpoint url
 * @param {object} params объект с какими-то данными
 */
export const getRequest = async (dispatch, url, params, token) => {
	try {
		const response = await axios.get(url, {
			headers: {
				'x-token': token,
			},
		});
		if (params.actionName) {
			dispatch({ type: params.actionName, payload: response.data });
		}
		return response.data;
	} catch (error) {
		return 'error';
	}
};

/**
 * функция, которая делает patch запрос по переданному end point
 * @param {string} url url на который мы будем делать запрос
 * @param {object} body данный которые хотим обновить
 */
export const patchRequest = async (url, body, token) => {
	try {
		const data = await axios
			.patch(url, body, {
				headers: {
					'x-token': token,
				},
			})
			.then((response) => response.data);
		return data;
	} catch (error) {
		return {
			isError: true,
			error: error.response,
		};
	}
};

/**
 * функция для post запроса
 * @param {string} url url на который мы будем делать запрос
 * @param {object} body объект, который мы хотим отправить на сервер
 * @returns
 */
export const postRequest = async (url, body, token) => {
	try {
		const data = await axios
			.post(url, body, {
				headers: {
					'x-token': token,
				},
			})
			.then((response) => response.data);
		return data;
	} catch (error) {
		return {
			isError: true,
			error: error.response,
		};
	}
};

/**
 * функция для удаления чего либо
 * @param {string} url url на который мы будем делать запрос
 */
export const deleteRequest = async (url, token) => {
	try {
		const data = await axios
			.delete(url, {
				headers: {
					'x-token': token,
				},
			})
			.then((response) => response.data);
		return data;
	} catch (error) {
		return {
			isError: true,
			error: error.response,
		};
	}
};
