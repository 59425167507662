import styled from 'styled-components/macro';
import { Button, Tooltip } from '@material-ui/core';
import { editorConstants } from '../../../constants/editorConstants';
/**
 * стили для компонента codeEditorSelect
 */
export const CustomFormControl = styled.div`
    position: absolute !important;
    top: ${(props) => (props.startline - 1) * editorConstants.lineHeight - props.scrolltop - 2 + 'px'};
    left: ${(props) => props.left - props.scrollleft + 'px'};
    width: ${(props) => {
        return (
            Math.ceil((props.endcolumn - props.startcolumn + props.selectedSchemeId + 1) * 1.8) *
                editorConstants.symbolWidth +
            'px'
        );
    }};
    height: 19px;
`;

export const CodeEditorButton = styled(Button)`
    width: 100%;
    height: 100%;
    text-transform: none;
    background-color: rgba(255, 255, 255);
    white-space: nowrap;
    font-size: 12px;
    overflow: hidden;
    :hover {
        background-color: rgba(255, 255, 255);
    }
`;
