/**
 * массив цветов
 */
export const colorsArray = [
	{ scheme: '#90EE90', border: '#32CD32' },
	{ scheme: '#FFB6C1', border: '#F08080' },
	{ scheme: '#87CEFA', border: '#1E90FF' },
	{ scheme: '#FFE4E1', border: '#00CED1' },
	{ scheme: '#FFC0CB', border: '#DDA0DD' },
	{ scheme: '#F5DEB3', border: '#D2B48C' },
	{ scheme: '#87CEEB', border: '#6A5ACD' },
	{ scheme: '#FFEFD5', border: '#FFDAB9' },
	{ scheme: '#A480CF', border: '#779BE7' },
	{ scheme: '#BA5536', border: '#A43820' },
];
