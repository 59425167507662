import { Tooltip } from '@material-ui/core';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MonacoEditor, { monaco } from 'react-monaco-editor';
import Context from '../../../store/context';
import { ActionsTypes } from '../../../store/reducer';
import checkLineForBrackets from '../../../utils/monacoUtils/checkLineForBrackets';
import codeEditorHotKeys from '../../../utils/monacoUtils/codeEditorHotKeys';
import codeTextTransformer from '../../../utils/monacoUtils/codeTextTransformer';
import getRangesFromEditor from '../../../utils/monacoUtils/getRangesFromEditor';
import getValuesFromEditor from '../../../utils/monacoUtils/getValuesFromEditor';
import ModalContainer from '../../modals/modalContainer';
import ShowFullSchemeModal from '../../modals/monacoModals/showFullSchemeModal';
import ButtonsForEditor from './buttonsForEditor';
import {
    TitleWrapper,
    EditorWrapper,
    MonacoWrapper,
    TitleButtonsWrapper,
    CustomButton,
    ShowFullButton,
    FullScreenIcon,
    Buttons,
} from './codeEditor-styles';
import CodeEditorSelect from './codeEditorSelect';
import fullScreen from '../../../assets/icons/fullScreen.svg';
import FullScreenSchemeEditorModal from '../../modals/fullScreenSchemeEditorModal/fullScreenSchemeEditorModal';
import proccesExamplesForSchemeLink from '../../../utils/processExamplesForSchemeLink';

/**
 * Окно редактора кода
 * @param {string || null} schemeAlias описание для схемы
 * @param {string} schemeName название схемы
 * @param {number} groupSelectData id группы
 * @param {string} schemeId id схемы
 * @param {function} setSchemeName функция, которая устанавливает значениу имени в localstate
 * @param {function} setSchemeAlias функция, которая устанавливает значение описания в localstate
 * @param {function} setGroupSelectData функция, которая устанавливает значениe id группы в localstate
 * @param {examples} examples массив примеров
 */
const CodeEditor = ({
    schemeAlias,
    schemeName,
    groupSelectData,
    schemeId,
    setSchemeName,
    setSchemeAlias,
    setGroupSelectData,
    examples,
}) => {
    const { state, dispatch } = useContext(Context);
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [code, setCode] = useState('');
    const [ranges, setRanges] = useState([]);
    const [editorValues, setEditorValues] = useState([]);
    const [selectButtonDisabled, setSelectButtonDisabled] = useState(true);
    const editorRef = useRef(null);
    const { t } = useTranslation();
    const [showFullScheme, setShowFullScheme] = useState(false);
    const [showFullScreenEditor, setShowFullScreenEditor] = useState(false);

    const closeFullScheme = () => setShowFullScheme(false);
    const openFullScheme = () => setShowFullScheme(true);

    const closeFullScreenEditor = () => setShowFullScreenEditor(false);
    const openFullScreenEditor = () => setShowFullScreenEditor(true);
    useEffect(() => {
        if (state.selectedScheme.shortScheme) {
            if (code.replace(/\s/g, '') === codeTextTransformer(state.selectedScheme.shortScheme).replace(/\s/g, '')) {
                dispatch({ type: ActionsTypes.SET_MONACO_CODE_EQUAL_TO_STATE_CODE, payload: true });
            } else {
                dispatch({ type: ActionsTypes.SET_MONACO_CODE_EQUAL_TO_STATE_CODE, payload: false });
            }
        }
    }, [code, state.selectedScheme.shortScheme, dispatch]);

    /**
     * устанавливает Hot key (Ctrl+d)
     */
    useEffect(() => {
        codeEditorHotKeys(editorRef.current.editor, state.schemes);
    }, [editorRef, state.schemes]);

    /**
     * cсрабатывает при изменении выбранной схемы.
     * Функция устанавливает отображение схемы в исходное
     * положение(показ маленькой схемы).
     * если есть shortSchema, то устанавливает код редактора равной этой схеме,
     * иначе, код редактора - пустая строка
     */
    useEffect(() => {
        monaco.editor.remeasureFonts();
        const ranges = getRangesFromEditor(editorRef.current.editor);
        setRanges(() => ranges);
        setSelectButtonDisabled(true);
        if (state.selectedScheme.shortScheme) {
            setCode(codeTextTransformer(state.selectedScheme.shortScheme));
        } else {
            setCode('');
        }
        // eslint-disable-next-line
    }, [state.selectedScheme.schemeName]);

    //обработчик нажатия на кнопку "copy link", открывае модальное окно с выбором консьюмера
    const copyLinkHandler = (_) => {
        const copyability = proccesExamplesForSchemeLink(examples);
        dispatch({ type: ActionsTypes.SET_INFO_FOR_ALIAS_COPY, payload: { isOpen: true, schemeAlias, copyability } });
    };

    /**
     * формирование массива компонентов select, стили каждого из компонентов будут меняться при изменении
     * ranges, code, scrollTop
     */
    const selectorsArr = useMemo(() => {
        return ranges.map((item) => (
            <CodeEditorSelect
                key={item.startLineNumber - Math.random()}
                startLine={item.startLineNumber}
                startColumn={item.startColumn}
                endColumn={item.endColumn}
                scrollTop={scrollTop}
                schemeId={schemeId}
                editorValues={editorValues}
                editorRef={editorRef}
                scrollLeft={scrollLeft}
            />
        ));
    }, [schemeId, ranges, scrollTop, editorValues, scrollLeft]);

    /**
     * обработчик нажатия на конпку "добавить select __schema__"
     * добавляет в код редактора надпись ("__schema__": id первой схемы из массива,
     * пришедшего с бэка)
     */
    const addSchemeSelectHandler = (editorRef) => () => {
        //получение координат курсора
        const selection = editorRef.current.editor.getSelection();

        //получение значения строки на которой находится курсор
        const lineValue = editorRef.current.editor.getModel().getLineContent(selection.startLineNumber);

        //если функция вернула true, то вставка разрешена
        if (checkLineForBrackets(lineValue, selection.startColumn - 1)) {
            setSelectButtonDisabled(false);
            const id = { major: 1, minor: 1 };
            let text = '';
            let spaceCount = 0;
            if (state.schemes.length > 0) {
                text = `"__schema__": ${state.schemes[0].id}`;
                spaceCount = Math.floor((text.length + 2) * 1.8) - text.length;

                if (lineValue.includes(',')) {
                    text += ' '.repeat(spaceCount - 1) + ',';
                }
            }
            const op = { identifier: id, range: selection, text, forceMoveMarkers: true };
            editorRef.current.editor.executeEdits('my-source', [op]);
        }
    };

    //настройки для редактора
    const options = {
        selectOnLineNumbers: true,
        lineHeight: 19,
        automaticLayout: true,
        fontSize: 14,
        fontFamily: 'Monaco, monospace',
        readOnly: state.versionInfo?.stable ? true : false,
        minimap: {
            enabled: false,
        },
    };

    /**
     * функция срабатывает, когда редактор вмантировался на страницу
     * @param {object} editor это экземпляр редатора
     */
    const editorDidMount = (editor) => {
        const ranges = getRangesFromEditor(editor);
        setRanges(() => ranges);

        //срабатывает при изменении контента в самом редакторе
        editor.getModel().onDidChangeContent(() => {
            const code = editor.getValue();
            setCode(() => code);
            const ranges = getRangesFromEditor(editor);
            setRanges(() => ranges);
            const values = getValuesFromEditor(editor);
            setEditorValues(() => values);
        });

        //срабатывает при скроле в редакторе
        editor.onDidScrollChange((e) => {
            const ranges = getRangesFromEditor(editor);
            setRanges(() => ranges);
            setScrollTop(() => e.scrollTop);
            setScrollLeft(() => e.scrollLeft);
        });

        editor.onDidChangeCursorPosition(() => {
            //получение координат курсора
            const selection = editor.getSelection();
            //получение значения строки на которой находится курсор
            const lineValue = editor.getModel().getLineContent(selection.startLineNumber);

            //если функция вернула true, то вставка разрешена и разблокируем кнопку
            if (checkLineForBrackets(lineValue, selection.startColumn - 1)) {
                setSelectButtonDisabled(false);
            } else {
                setSelectButtonDisabled(true);
            }
        });
    };

    return (
        <>
            <ModalContainer open={showFullScheme} handleClose={closeFullScheme}>
                <ShowFullSchemeModal buildedScheme={state.selectedScheme.buildedScheme} />
            </ModalContainer>
            <ModalContainer open={showFullScreenEditor} handleClose={closeFullScreenEditor}>
                <FullScreenSchemeEditorModal
                    selectButtonDisabled={selectButtonDisabled}
                    setSelectButtonDisabled={setSelectButtonDisabled}
                    addSchemeSelectHandler={addSchemeSelectHandler}
                    schemeId={schemeId}
                    setCode={setCode}
                    code={code}
                    schemes={state.schemes}
                    groupSelectData={groupSelectData}
                    schemeName={schemeName}
                    schemeAlias={schemeAlias}
                    setSchemeName={setSchemeName}
                    setSchemeAlias={setSchemeAlias}
                    setGroupSelectData={setGroupSelectData}
                    closeFullScreenEditor={closeFullScreenEditor}
                />
            </ModalContainer>
            <EditorWrapper>
                <TitleWrapper>
                    <Buttons>
                        <TitleButtonsWrapper>
                            <ShowFullButton
                                variant='outlined'
                                onClick={openFullScheme}
                                disabled={
                                    schemeId ? !state.isMonacoCodeEqualToStateCode || state.versionInfo?.stable : true
                                }
                            >
                                {t('Built Scheme')}
                            </ShowFullButton>
                        </TitleButtonsWrapper>
                        <TitleButtonsWrapper>
                            <Tooltip title='Ctrl + D / Cmd + D'>
                                <span>
                                    <CustomButton
                                        disabled={selectButtonDisabled}
                                        variant='outlined'
                                        onClick={addSchemeSelectHandler(editorRef)}
                                        color='primary'
                                    >
                                        {t('Add existing schema')}
                                    </CustomButton>
                                </span>
                            </Tooltip>
                        </TitleButtonsWrapper>

                        <TitleButtonsWrapper>
                            {schemeAlias && (
                                <CustomButton onClick={copyLinkHandler} variant='outlined'>
                                    {t('Copy link')}
                                </CustomButton>
                            )}
                        </TitleButtonsWrapper>
                    </Buttons>
                    <div>
                        <Tooltip title='Full screen editor'>
                            <FullScreenIcon onClick={openFullScreenEditor} src={fullScreen} alt='show full screen' />
                        </Tooltip>
                    </div>
                </TitleWrapper>
                <MonacoWrapper boxShadow={2}>
                    <MonacoEditor
                        ref={editorRef}
                        language='json'
                        theme='vs'
                        value={code}
                        options={options}
                        editorDidMount={editorDidMount}
                    />
                    {selectorsArr}
                </MonacoWrapper>
                <ButtonsForEditor
                    groupSelectData={groupSelectData}
                    schemeName={schemeName}
                    schemeAlias={schemeAlias}
                    schemeId={schemeId}
                    code={code}
                    setCode={setCode}
                    setSchemeName={setSchemeName}
                    setSchemeAlias={setSchemeAlias}
                    setGroupSelectData={setGroupSelectData}
                />
            </EditorWrapper>
        </>
    );
};

export default CodeEditor;
