/**
 * Функция делает пост реквест новой схемы, и если ответ положительный, она перезапрашивает все схемы и примеры для них, чтобы обновить конвас и боковую плашку
 */
const schemePostHelper = async (options) => {
    const {
        dispatch,
        versionInfo,
        groupSelectData,
        schemeAlias,
        schemeName,
        jsonCode,
        includedSchemesId,
        stageCusrsorPosition,
        postRequest,
        BASE_URL,
        token,
        enqueueSnackbar,
        getRequest,
        ActionsTypes,
        setCode,
        closeFullScreenEditor,
    } = options;

    //тело для post запроса
    const requestBody = {
        version_id: versionInfo.id,
        group_id: groupSelectData,
        alias: schemeAlias ? schemeAlias : null,
        name: schemeName,
        schema: jsonCode,
        included_schemas: includedSchemesId,
        frontend_meta: { coordinates: { x: stageCusrsorPosition.x, y: stageCusrsorPosition.y } },
    };
    const data = await postRequest(`${BASE_URL}schemas/`, requestBody, token);

    /**
     * ждем ответ с сервера, если ответ без ошибки, то
     * обновляем массив схем и закрываем окно редактора
     */
    if (!data.isError) {
        closeFullScreenEditor && closeFullScreenEditor();
        enqueueSnackbar('Scheme created', {
            variant: 'success',
        });
        getRequest(
            dispatch,
            `${BASE_URL}schemas/?version_id=${versionInfo.id}`,
            {
                actionName: ActionsTypes.GET_SCHEMES_ARRAY,
            },
            token,
        );

        await getRequest(
            dispatch,
            `${BASE_URL}examples/?version_id=${versionInfo.id}`,
            {
                actionName: ActionsTypes.GET_EXAMPLES_FOR_VERSION,
            },
            token,
        );

        dispatch({ type: ActionsTypes.SET_MONACO_CODE_EQUAL_TO_STATE_CODE, payload: true });
        dispatch({ type: ActionsTypes.SET_SCHEME_EDITOR, payload: false });
        setCode('');
    } else {
        const errorKey = Object.keys(data.error.data)[0];
        if (errorKey) {
            enqueueSnackbar(data.error.data[errorKey], {
                variant: 'error',
            });
        } else {
            enqueueSnackbar('Server error', {
                variant: 'error',
            });
        }
    }
};

export default schemePostHelper;
