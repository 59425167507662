import React from 'react';
import { urls } from '../constants/urls';
import { nodeParams } from '../constants/nodeParams';
import { headerParams } from '../constants/headerParams';
import { colorsArray } from '../constants/colorsArray';

/**
 * стейт всего приложения
 */
const Context = React.createContext({
    schemaSources: [],
    schemeVersions: [],
    selectedSource: null,
    isVesrionSelected: false,
    groupsForSource: [],
    consumersForSource: [],
    examplesForVersion: [],
    infoForAliasCopy: {},
    urls,
    nodeParams,
    headerParams,
    colorsArray,
    lang: 'eng',
    schemes: [],
    diagramsNodesArray: [],
    diagramsLinksArray: [],
    convasDiagramItems: [],
    windowDimensions: {},
    schemeEditor: false,
    versionInfo: null,
    schemeNameValidateError: false,
    schemeGroupValidateError: false,
    isMonacoCodeEqualToStateCode: true,
    selectedScheme: {
        // schemeName: '',
        // schemeAlias: '',
        // schemeGroup: '',
        // buildedScheme: '',
        // shortScheme: ''
        // examples: [],
        // schemeId: '',
        // schemeGroupId
    },
    stageCusrsorPosition: {},
    examplesForSave: [],
});

export default Context;
